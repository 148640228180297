body.new {
  .employee-form {
    width: 65%;
    .alert.error.new-employee {
      width: 86%;

      p {
        margin: 0rem;
      }

      table {
        width: 100%;
      }

      tbody > tr {
        display: flex;
        justify-content: flex-start;

        td {
          margin-right: 5rem;
          color: #ff3a3a;
        }
      }
    }
    form.edit_employee, form.new_employee {
      width: 100%;

      table.admin.form {
        tbody::after {
          content: "";
          display: block;
          height: 1.4em;
        }

        input[type=text] {
          width: 350px;
        }

        input[type=date] {
          font-family: "Lucida Grande";
        }

        input.datepicker {
          width: 8em;
        }

        input + label {
          margin: 0 1em 0 0.2em;
        }

        select {
          width: 240px;
        }

        .hidden {
          display: none;
        }
      }

      a {
        margin-left: 1rem;
        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        &.add_nested_fields {
          margin-left: 0;
          padding: 0.1rem 0.6rem;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
        }
      }

      table#hourly-rates {
        thead {
          font-size: 11px;
          line-height: 1.5rem;
        }
      }
    }

    div.contracts {
      table.admin {
        th, td {
          padding: 0 1em 0.4em 1em;

          input.short {
            width: 3.2rem;
          }

          input.salary {
            width: 6.1rem;
          }
        }

        table.days {
          th, td {
            padding: 0.3rem 0.3rem 0 0;
          }
        }

        span#hours_per_period, span#periodic_salary {
          font-style: italic;
        }

        a#proposed {
          cursor: pointer;
        }
      }
    }
  }
}
