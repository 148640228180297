div.syncall {
  @include clearfix;
  width: 100%;

  button {
    float: right;
  }
}

div.alert.afas {
  margin-top: 0;
}

table.admin.afas {
  width: 100%;
  margin-bottom: 1.6rem;

  tr.error {
    display: none;
    color: red;

    td {
      white-space: pre-wrap;
      border-top: 0;
    }
  }

  th, td {
    padding: 0.8rem;
    border-top: 1px solid lightgrey;
  }

  th {
    padding: 0.3rem 0.8rem;
    border-top: 0;
  }

  td.action {
    img {
      display: none;
    }

    span.success {
      display: none;
      color: green;
      font-size: 1.9rem;
      font-weight: bold;
    }
  }
}
