.notes-container {
  margin: 1.1rem 1.6rem 3.2rem;
  padding: 1.6rem 3.2rem;
  background: white;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.10);

  table.notes {
    border-collapse: collapse;
    width: 100%;

    th:first-child {
      padding-bottom: 0.8rem;
      font-size: 17px;
    }

    tr.existing-note {
      border-top: 1px solid #eee;

      &:first-child {
        border-top: 2px solid #eee;
      }
    }

    td, th {
      padding: 1.1rem 1.6rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.date {
        white-space: nowrap;
      }

      &.body {
        width: 90%;
      }

      input {
        width: 100%;
      }

      a.grey-button {
        padding: 0.5rem 3.2rem;
      }

      i {
        font-size: 16px;
        color: #ccc;

        &:hover {
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}
