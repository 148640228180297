$vacation_color         : #DBADFF;
$vacation_request_color : #FFB878;
$vacation_request_text_color : #C47227;
$special_leave_color    : #A4BDFC;
$unpaid_leave_color     : #FBD75B;
$illness_color          : #FFD2D2;
$roster_free_color      : #888;
$public_holiday_color   : #fcf2b8;
$assigned_color         : #C6E9A0;
$available_color        : #5B9F11;
$unavailable_color      : #D8232A;
$undefined_color        : #ccc;
$birthday_color         : #418DF01A;
$anniversary_color      : #049B251A;

.availability_vacation { background: $vacation_color; }
.availability_illness { background: $illness_color; }
.availability_special_leave { background: $special_leave_color; }
.availability_unpaid_leave { background: $special_leave_color; }
.availability_rosterfree { background: $roster_free_color; }
.availability_holidays { background: $public_holiday_color; }
.availability_assgnd { background: $assigned_color; }
.availability_avail { background: $available_color; }
.availability_unav { background: $unavailable_color; }
.availability_undef { background: $undefined_color; }
.availability_birthday { background: $birthday_color; }
.availability_anniversary { background: $anniversary_color; }
.availability_standby { background-color: $unavailable_color; }
