body.new .afas-data-container {
  margin-top: 3.2rem;

  .filters-and-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table.finance-status {
    width: 100%;
    margin: 1.6rem 0;

    tr.employee-row {
      opacity: 0.3;
      filter: grayscale(1);
      &.checked  {
        opacity: 1;
        filter: unset;
      }
    }

    tr:nth-child(odd) {
      background-color: #F5F5F5;
    }

    th, td {
      padding: 0.3rem 0.8rem;
      border-right: 1px solid lightgrey;
    }

    th {
      background-color: #E3E3E3;
    }

    td {
      border-top: 1px solid lightgrey;
      border-right: 1px solid lightgrey;

      &.uptodate {
        background-color: #B3FFC6;
      }

      &.toupdate {
        background-color: #FFECB3;
      }

      &.toinsert {
        background-color: #FFC6B3;
      }

      &.notfound {
        color: red;
      }

      &.charge-hours,
      &.special-leave-hours,
      &.illness-hours {
        padding: 0;
      }

      table {
        width: 100%;

        tr:nth-child(1) td {
          border-top: 0;
        }

        td {
          border-top: 1px dotted lightgrey;
          border-right: 1px dotted lightgrey;
        }

        td.label {
          width: 3.2rem;
        }
      }

      .tooltip {
        table {
          margin: 1.6rem 0;
        }
      }
    }
  }

  .bulk-selection {
    margin: 2rem 0;

    a {
      cursor: pointer;
    }
  }
}
