.flatpickr-confirm {
	height: 30px;
	font-size: 12px;
	color: white;
	background-color: #2d89f5;
	border-radius: 4px;
	margin: 8px;
}

.flatpickr-monthSelect-month {
  font-size: 12px;
  margin: 2px 0.5px;
  padding: 4px 1px;
  width: 30%;
}

.flatpickr-monthSelect-month.flatpickr-disabled:hover,
.flatpickr-monthSelect-month.flatpickr-disabled:focus {
  border-color: #959ea9 !important;
  color: #eee !important;
}

.flatpickr-monthSelect-month.startRange {
  z-index: 1;
}

.flatpickr-monthSelect-month.inRange,
.flatpickr-monthSelect-month.inRange.today,
.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #eaf3fe;
  cursor: pointer;
  outline: 0;
  border-color: #eaf3fe;
}

.flatpickr-monthSelect-month.startRange {
  border-radius: 4px;
}

.flatpickr-monthSelect-month.endRange {
  border-radius: 4px;
}

.flatpickr-monthSelect-month.today {
  border: none;
}

.flatpickr-monthSelect-month.today.endRange {
  background: #d7e8fd;
  border: 1px solid #56a0f7!important;
  color: #393939;
  &:hover {
    background: #d7e8fd;
  border-color: #56a0f7;
  }
}

.flatpickr-monthSelect-month.today:hover {
  background: #eaf3fe;
  color: #393939;
  border: 1px solid #eaf3fe;
}

.flatpickr-monthSelect-month.inRange {
  border-radius: 4px;
  box-shadow: -1px 0 0 #eaf3fe, 5px 0 0 #eaf3fe;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: #d7e8fd;
  color: #393939;
  border-color: #56a0f7;
}

.flatpickr-monthSelect-month.today.selected {
  background-color: #d7e8fd;
  color: #393939;
  border-color: #56a0f7;
}

.flatpickr-monthSelect-month.startRange.endRange.selected {
  border-radius: 4px;
}

.flatpickr-monthSelect-month.inRange[aria-label^="March"],
.flatpickr-monthSelect-month.inRange[aria-label^="June"],
.flatpickr-monthSelect-month.inRange[aria-label^="September"],
.flatpickr-monthSelect-month.inRange[aria-label^="December"] {
  border-radius: 0 4px 4px 0;
  box-shadow: -5px 0 0 #eaf3fe
}

.flatpickr-monthSelect-month.inRange[aria-label^="January"],
.flatpickr-monthSelect-month.inRange[aria-label^="April"],
.flatpickr-monthSelect-month.inRange[aria-label^="July"],
.flatpickr-monthSelect-month.inRange[aria-label^="October"] {
  border-radius: 4px 0 0 4px;
  box-shadow: 5px 0 0 #eaf3fe;
}

.flatpickr-monthSelect-month.inRange[aria-label^="February"],
.flatpickr-monthSelect-month.inRange[aria-label^="May"],
.flatpickr-monthSelect-month.inRange[aria-label^="August"],
.flatpickr-monthSelect-month.inRange[aria-label^="November"] {
  border-radius: 0;
  box-shadow: 5px 0 0 #eaf3fe, -5px 0 0 #eaf3fe;
}

.flatpickr-calendar {
  width: 222.875px;
}

.numInputWrapper input,
.numInputWrapper span {
  display: none;
}

.numInputWrapper:hover {
  background: none;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none;
}

.flatpickr-current-month {
  font-weight: 400;
}
.flatpickr-current-month input.cur-year {
  cursor: default;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-input[readonly] {
  width: 222.875px;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 12px;
  color: #303030;
  border: 1px solid #B8B8B8;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  background-image: linear-gradient(to bottom, #FEFEFE, #F2F2F2);
  height: 30px;
  box-shadow: 0 1px 0 #F2F2F2
}
