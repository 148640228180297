a.button,
button.new,
input[type=submit].new {
  display: inline-block;
  padding: 1rem 1.25rem;
  border-radius: 0.4rem;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  gap: 0.5rem;

  &.with-svg {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &.filter-button {
    border: 1px solid #ddd;
    background: #fff;
    font-weight: normal;
  }

  &.reset-button {
    border: none;
    background: #444444;
    color: #fff;
  }


  &.transparent-button {
    border: 1px solid #aaa;

    &:hover:not(.disabled) {
      background: #ddd;
    }
  }

  &.white-button {
    background-color: white;
    color: #333333;
    border: 1px solid #CCCCCC;
    &:hover:not(.disabled) {
      background-color: darken( white, 5%);
      color: #333333;
      border-color: darken(#CCCCCC, 5%);
    }
  }

  &.grey-button {
    background: #eee;
    border: 1px solid #eee;
    &:hover:not(.disabled) {
      background: #ddd;
      border-color: #ddd
    }
  }

  &.dark-grey-button {
    background-color: #ddd;
    border: 1px solid #ddd;
    &:hover:not(.disabled) {
      background-color: #ccc;
      border-color: #ccc;
    }
  }

  &.red-button {
    background-color: #D8232A;
    color: white;
    border: 1px solid #D8232A;
    &:hover:not(.disabled) {
      background-color: lighten( #D8232A, 10%);
      color: white;
      border-color: lighten( #D8232A, 10%);
    }
  }

  &.transparent-button {
    padding: 0.6rem 2rem;
    background-color: transparent;
    border: 0.1rem solid #CCCCCC;
    &.red {
      color: #D8232A;
    }
  }

  &.green-button {
    background-color: #83C548;
    color: white;
    border: 1px solid #83C548;
    &:hover:not(.disabled) {
      background-color: lighten( #83C548, 10%);
      color: white;
      border-color: lighten( #83C548, 10%);
    }
  }

  &.excel-button {
    position: relative;
    padding-left: 3.2rem;
    font-size: 1.3rem;
    font-weight: normal;
    &:before {
      position: absolute;
      top: 0.8rem;
      left: 1.3rem;
      content: '\f1c3';
      font-family: 'Font Awesome 5 Pro';
    }
  }

  &.blue-button {
    background-color: #2D89F5;
    color: white;
    border: 1px solid #2D89F5;
    &:hover:not(.disabled) {
      background-color: #2c5bf5;
      color: white;
      border-color: #2c5bf5;
    }
    &.spinner{
      min-width: 9rem;
    }
  }

  &.excel-blue-button {
    position: relative;
    padding-left: 3.3rem;
    padding-right: 18px;
    padding-top: 11px;
    font-size: 16px;
    font-family: "Lato";
    text-align: center;
    line-height: 20px;
    box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.15);
    &:before {
      position: absolute;
      top: 1.1rem;
      left: 1.3rem;
      font-size: 14px;
      content: '\f1c3';
      font-weight: 900;
      font-family: 'Font Awesome 5 Pro';
    }
  }

  &.excel-blue-button-margin {
    margin-top: 1.1rem;
    margin-bottom: 3rem;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.readonly {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.centered {
    display: block;
    margin: 3rem auto;
  }
}

input.toggle[type=checkbox] {
  height: 0;
  width: 0;
  margin: 0;
  visibility: hidden;

  &:checked + label.toggle {
    background: #83C548;
  }

  &:checked + label.toggle:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}

label.toggle {
  cursor: pointer;
  text-indent: -9999px;
  width: 26px;
  height: 15px;
  background: rgb(160, 160, 160);
  display: inline-block;
  border-radius: 20px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 11px;
    height: 11px;
    background: #fff;
    border-radius: 11px;
    transition: 0.3s;
  }
}
