.export-filters {
  display: flex;
  flex-direction: column;
  width: 50rem;

  .assignment-status {
    width: 26rem;
  }

  .mandatory {
    display: flex;
    gap: 0 1rem;
  }

  .date-input input {
    width: 10rem;
  }
}
