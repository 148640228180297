html.login {
  background: #f3f3f3;

  body.new.login {
    display: flex;
    min-width: 0;
    align-items: center;
    justify-content: center;
    background: radial-gradient(ellipse at center,#fff 0%,#f3f3f3 75%);
    background-size: 800px 800px;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .login-container {
      width: 23rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 5rem;

      h1 {
        margin-bottom: 2rem;
        font-size: 24px;
        font-weight: normal;
        font-family: Helvetica, Arial, sans-serif;

        .and {
          color: #D8232A;
        }
      }
      .button {
        padding: 0.9rem 3.2rem;
        width: 100%;
        border: 0 solid;
        color: rgb(105, 105, 105);
        font-size: 13px;
        line-height: 17px;
        border-radius: 4px;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
          width: 2rem;
          height: auto;
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }

        &.google {
          cursor: pointer;
          background-color: rgb(239, 239, 239);
          &:hover {
            background-color: lighten(#cbcbcb, 10%);
            cursor: pointer;
          }
        }

        &.outlook {
          cursor: pointer;
          background-color: rgb(239, 239, 239);
          &:hover {
            background-color: lighten(#cbcbcb, 10%);
            cursor: pointer;
          }
        }
      }

      input {
        width: 18rem;
        margin: 1rem 0;

        &.field {
          padding: 0 1.6rem;
          height: 35px;
          border: 1px solid #CCCCCC;
          border-radius: 3px;
          font-size: 1.3rem;

          &:focus {
            outline: none;
          }

          &:hover {
            border-color: darken(#CCCCCC, 20%);
          }
        }

        &.button {
          padding: 0.6rem 3.2rem;
          width: 100%;
          border: 0 solid;
          background-color: #585858;
          color: white;
          font-size: 13px;
          line-height: 17px;
          border-radius: 4px;
          text-align: center;

          &:hover {
            background-color: lighten(#585858, 10%);
            cursor: pointer;
          }
        }
      }

      .alert.error {
        font-size: 1.1rem;
        border-radius: 3px;
      }
    }
  }
}
