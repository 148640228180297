.company-totals {
  .selectize-control-company-totals {
    padding: 1rem 0;
    width: fit-content;
  }

  .filters {
    display: flex;
    justify-content: space-between;
  }
}
