html {
  height: 100%;
  font-size: 10px;
  overflow-y: scroll;

  body {
    display: table;
    margin: 0;
    height: 100%;
    min-width: 1120px;
    width: 100%;
    font-size: $base-font-size;
    font-family: $base-font-family;

    main {
      display: flex;
      min-height: calc(100% - 5.6rem);
    }

    .php-container {
      display: flex;
      flex-grow: 1;
      min-height: 100%;

      > pre {
        position: absolute;
        top: 4.3rem;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #ffba4d;
        padding: 1.4rem 1.4rem 0;
        max-height: 0.4rem;
        overflow: hidden;
        &:before {
          top: 0.3rem;
          left: 1rem;
          position: absolute;
          font-family: Helvetica;
          content: "Errors found when loading the page. Please contact Voormedia.";
        }
        &:hover {
          max-height: 1000rem;
          padding-bottom: 1.4rem;
        }
      }
    }

    .rails-container,
    .php-inner-container {
      position: relative;
      padding: 3.2rem;
      flex-grow: 1;

      .full-width {
        min-width: 95rem;
        position: relative;
        margin: 0 -3.2rem -3.2rem;
        padding: 3.2rem;
        background-color: $grey-background-color;
      }
    }
  }

  body.new {
    a {
      color: #000;
      &:hover:not(.disabled) {
        color: #444;
      }
      &.action {
        text-decoration: none;
        cursor: pointer;
      }
    }
    h1 {
      margin-top: 0;
      font-size: 2.4rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      span.section-title {
        display: block;
        font-size: 1.3rem;
        font-weight: normal;
        line-height: 1.8rem;
        opacity: 0.3;
      }

      a.button {
        margin-left: 2rem;
        vertical-align: middle;
      }
    }
    h2 {
      font-size: 2.2rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    h3 {
      font-size: 1.8rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    span.zero {
      color: #bbb;
    }

    .box {
      background-color: #e5e5e5;
      padding: 0.6rem;
      margin: 1.6rem 1.6rem 0 0;
      border-radius: 4px;
    }
  }

  a.pdf-link {
    background: red;
  }

  a.pdf-link img {
    margin-right: 0.3rem;
  }
}
