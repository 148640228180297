#account {
  > a {
    position: absolute;
    top: 5rem;
    left: 22.5rem;
  }
  .main-details {
    display: flex;
    width: 50rem;
    border-radius: 0.8rem;
    background-color: #F3F3F3;

    .field-group {
      .field {
        width: 100%;

        input[type=text] {
          font-size: 1.3rem;
          padding: 0.6rem;
          border-radius: 0.3rem;
          width: calc(100% - 1.2rem);
        }

        .selectize-control {
          min-width: 6.5rem;
        }
      }
      .cancel {
        margin-left: 1rem;
      }
    }
  }
  input[type=text] {
    border: 0.1rem solid #b8b8b8;
    background: white;
  }

  .selectize-input {
    background: white;
  }
}
