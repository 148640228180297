/* Conversion measure. */
$base-font-size: 13px;
$base-line-height: 1.4em;
$base-font-factor: calc(16px / $base-font-size);
$base-font-family: Roboto, sans-serif;
$new-font-family: Roboto, sans-serif;

$admin-table-color: #eee;

@import "config/variables";
@import "mixins/_mixins";
@import "feedback/feedback";

@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "vendor/datatables";
@import "vendor/date_picker";
@import "vendor/fontawesome";
@import "vendor/jquery.timepicker";
@import "vendor/rangeslider";
@import "vendor/react_calendar";
@import "vendor/react_tooltip";
@import "vendor/selectize";
@import "vendor/spectrum";
@import "vendor/tocbot";

@import "shared/alert";
@import "shared/applicable_days";
@import "shared/billing_address";
@import "shared/buttons";
@import "shared/fonts";
@import "shared/selections";
@import "shared/double_spinner";
@import "shared/field_groups";
@import "shared/page_loader";
@import "shared/square_spinner";
@import "shared/forms_2018";
@import "shared/tooltip";
@import "shared/static_tooltip";
@import "shared/selectize_fields";

@import "admin/base/_base";
@import "admin/base/_datepicker_grid_pop_up";
@import "admin/base/_forms";
@import "admin/base/_text";

@import "admin/badges";
@import "admin/calendar";
@import "admin/forms";
@import "admin/header";
@import "admin/nav";
@import "admin/popup";
@import "admin/sidebar";
@import "admin/sprites";
@import "admin/tables";
@import "admin/tags";
@import "admin/wysiwyg";
@import "admin/login/login";

@import "admin/crm/companies";
@import "admin/crm/feedback-results";
@import "admin/crm/global";
@import "admin/crm/survey-results";

@import "admin/hrm/absences";
@import "admin/hrm/employee";
@import "admin/hrm/employee_overview";
@import "admin/hrm/hours_summaries";
@import "admin/hrm/vacations";

@import "admin/finance/afas_finance_status";
@import "admin/finance/afas_sync_status";
@import "admin/finance/assignment_status";
@import "admin/finance/contracts";
@import "admin/finance/company_totals";
@import "admin/finance/invoice_preview";
@import "admin/finance/invoice_specifications";
@import "admin/finance/invoice_totals";
@import "admin/finance/invoicing_overview";
@import "admin/finance/invoicing_overview_table";
@import "admin/finance/period_selector";
@import "admin/finance/salaries";
@import "admin/finance/salary_export";

@import "admin/operations/employee_dropdown";
@import "admin/operations/schedule_table";
@import "admin/operations/teams";
@import "admin/operations/unassigned";
@import "admin/operations/assignment/assignment_blueprints";
@import "admin/operations/assignment/assignment_changes";
@import "admin/operations/assignment/assignment_exports";
@import "admin/operations/assignment/assignment_form";
@import "admin/operations/assignment/assignment_history";
@import "admin/operations/assignment/assignments";

@import "admin/other/accounts";
@import "admin/other/knowledge_base";
@import "admin/other/service_types";
@import "admin/other/users_business_units";

@import "admin/user_management/user_overview";

@import "admin/reporting/dashboard";
@import "admin/reporting/gross_margin";
@import "admin/reporting/filter_bar";
@import "admin/reporting/heatmaps";

@import "shared/php";
@import "shared/planning_tooltip";
@import "shared/locations_planner";
@import "../../node_modules/flatpickr/dist/flatpickr";
@import "../../node_modules/flatpickr/dist/plugins/monthSelect/style";
@import "../../node_modules/flatpickr/dist/plugins/confirmDate/confirmDate";
@import "../../node_modules/tinymce/skins/lightgray/skin.min";

@import "admin/components/_content-blocks";
@import "admin/components/_datepicker-trigger";
@import "admin/components/_flatpickr";
@import "admin/components/_forms";
@import "admin/components/_listing";
@import "admin/components/_notes";
