div.tooltip, div.tip {
  visibility: hidden;
  position: absolute;
  z-index: 100;
  max-width: 400px;
  padding: 0.2em 0.5em 0.3em;
  border: 1px solid #b0b7bb;
  background-color: #f9fafb;
  text-align: left;
  -moz-box-shadow: 1px 5px 5px rgba(0,0,0,.2);
  -webkit-box-shadow: 1px 5px 5px rgba(0,0,0,.2);
  box-shadow: 1px 5px 5px rgba(0,0,0,.2);

  div.main & {
    /* Employee and client login. */
    font-size: 0.9em;
  }
}
