div.invoicespecification {
  table.admin {
    width: 100%;
  }
  div.navigation {
    margin-top: 0.5em;
    div.explanation {
        float: right;
    }
  }
  h3 {
    line-height: 1em;
    background-color: red;
  }
  table {
    table.invoicehours {
      margin-bottom: 40px;
      input {
        text-align: right;
        min-width: 1.6rem;
      }
      select { width: 100px; }
      td:first-child,
      td:first-child + td { width: 80px; }
      td:first-child + td + td,
      td:first-child + td + td + td { white-space: nowrap; }
      td:first-child + td,
      td:first-child + td + td,
      td:first-child + td + td + td { text-align:right; }
      input.smallinput { width: 40px; }
      td.info_icon { width: 20px; vertical-align:middle; }
    }
    table.surcharge_table {
      td.total { font-weight: bold; }
      tr.spacer { height: 1px; }
      tr.spacer td { line-height:1px; height:1px; font-size:1px; background-color:black; }
    }
    table.invoicetotals {

    }
    table.invoiceexpenses {
      margin-right: 1em;
      td {
        white-space: nowrap;
        input {
          text-align:right;
        }
      }
    }
    div.uploadedfiles {
      padding-left: 4px;
      div.file {
        height: 2em;
        a span {
          float: left;
          padding-right: 5px;
        }
        div.about {
          display: inline;
          div.description {
            float: left;
            overflow: hidden;
            white-space: nowrap;
            width: 185px;
          }
          div.specification {
            float: left;
            padding-left: 8px;
            white-space: nowrap;
            width: 80px;
          }
          div.date {
            float: left;
          }
        }
      }
    }
    div.notes {
      max-width: 34em;
      input { width: 200px; }
      textarea { width: 200px; }
      a.notetitle {
        border-bottom: 1px solid #eee;
        display: block;
        font-weight: bold;
        margin-bottom: 0.6em;
        margin-top: 0.3em;
      }
      a.billingicon {
        float: right;
      }
      div.block,
      div.terms {
        margin-bottom: 1.5em;
      }
      div.text {
        cursor: text;
        font-style: italic;
        &:hover { background-color: #eee; }
      }
    }
  }
}
