.page-loader {
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  z-index: 10;

  .double-spinner {
    display: block;
    position: fixed;
    top: calc(50% - 5rem);
    left: calc(50% - 5rem);
    &:after {
      width: 10rem;
      height: 10rem;
    }
  }

  &.relative {
    .double-spinner {
      position: absolute;
      top: calc(50% - 2.5rem);
      left: calc(50% - 2.5rem);
      margin: 0;
      &:after {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
