html body form.new {
  input,
  textarea {
    font-size: 1.3rem;
    padding: 0.6rem;
    border-radius: 0.3rem;
    border: 0.1rem solid #ccc;

    &:focus {
      outline: none;
    }

    &:disabled,
    &:read-only {
      background-color: #fdfdfd;
      border-color: #dcdcdc;
      color: lightgrey;
      cursor: not-allowed;
    }
  }

  label {
    margin: 1rem 0;
    display: inline-block;
  }

  textarea {
    padding: 1rem;
  }

  input[type="submit"] {
    padding: 1rem 1.25rem;
    color: #fff;
    background-color: #2d89f5;
    border: 0;

    &:disabled {
      background-color: lighten(#2d89f5, 20);
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: darken(#2d89f5, 10%);
    }
  }

  .time-input,
  .date-input {
    position: relative;
    &:after {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      opacity: 0.5;
      pointer-events: none;
      font-family: "Font Awesome 5 Pro";
    }
  }

  .euro-input {
    position: relative;
    &:before {
      position: absolute;
      top: 0.9rem;
      left: 0.8rem;
      font-size: 1.1rem;
      opacity: 0.5;
      font-family: "Font Awesome 5 Pro";
    }

    input {
      padding-left: 2rem;
      width: 6rem;
    }
  }

  .time-input:after {
    content: "\f017";
  }

  .date-input:after {
    content: "\f073";
  }

  .euro-input:before {
    content: "\f153";
  }
}

html body form {
  .input-with-label {
    label {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .input-group {
    display: flex;

    > * {
      margin-right: 1rem;
    }
  }
}

.changelog-picker {
  .datepicker-grid {
    width: 11rem;
  }
}
