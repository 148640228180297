
.log-button {
  padding: 0.6rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  font-size: 1.3rem;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  i {
    padding-left: 0.6rem;
  }
}

.white-popup-block {
  .generic-table {
    min-width: 40rem;
  }

  table.history {
    thead th,
    tbody td {
      padding: 1.2rem;
    }
  }
}
