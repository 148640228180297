reporting-heatmap > div {
  display: block;
  width: 100%;
  min-height: calc(100vh - 30rem);
}

.heatmap-page {
  #filters {
    &.all_employees, &.fixed_employees, &.flexible_employees {
      .filter.business-type {
        opacity: 0.1;
        pointer-events: none;
      }
    }

    &.venues {
      .filter.business-unit {
        opacity: 0.1;
        pointer-events: none;
      }
    }
  }
}
