$alternating-row-background-color: #f3f3f3;

body.new {
  h1 {
    a.show-past-absences {
      margin-left: 1rem;
      padding: 0.2rem 1rem;
      font-size: 1.3rem;
    }
  }

  .main-details.pending-vacation {
    padding: 3rem;
    width: unset;
    background-color: #ffedd6;
  }

  .vacation-requests,
  .absence-requests {
    display: flex;

    .pending-requests {
      width: 80rem;
      margin-right: 4rem;
    }
  }

  .leave_type_selectize {
    width: 100%;
    flex-grow: 1;
    max-width: 22rem;
  }

  .existing-absence {
    background-color: #ffedd6;

    .absence-details {
      position: relative;
      background: linear-gradient(270deg, transparent 0%, rgba(0, 0, 0, 0.03) 60%);

      .actions {
        position: absolute;
        right: 3rem;
        bottom: 4rem;

        input {
          width: 10rem;
        }

        a {
          width: 8rem;
        }
      }
    }
  }

  .field-group.for-special-leave,
  .field-group.for-unpaid-leave,
  .field-group.for-vacation,
  .field-group.for-custom-days {
    display: none;
  }

  .field-group.for-illness {
    display: block;
  }

  .field-group.for-custom-days {
    flex-direction: column;
    .column > label {
      margin: 0;
    }
    .in-line {
      display: flex;
      flex-direction: row;
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        background-color: #ccc;
        color: white;
        margin-right: 0.3rem !important;
      }

      input[type="checkbox"]:checked + label {
        background-color: #2d89f5;
        color: white;
      }
    }
  }

  .field-group .field .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    label {
      margin-right: 1rem;
    }

    .date-input:has(.datepicker:disabled) {
      opacity: 0.3;
    }
    .datepicker:disabled {
      cursor: not-allowed;
      color: #333;
    }
    .number-input {
      margin-right: 1rem;
      input {
        color: #333;
      }
      &:has(input:disabled) {
        opacity: 0.3;
      }
    }
  }

  .field-group .field label[for="absence_end_date"] {
    align-self: flex-start;
  }

  .field#absence-clear-schedule-checkbox {
    label {
      &:first-of-type {
        margin-top: 1.3rem;
      }

      input[type="number"] {
        width: 4rem;
      }
    }
  }

  .hour-day-toggle {
    margin-bottom: 1.5rem;

    a {
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        text-decoration: underline;
      }
      &.active {
        opacity: 1;
      }
    }
  }

  table.absences {
    border: none;
    border-radius: 0.8rem;
    background: unset;
    white-space: nowrap;
    &.requests {
      margin-bottom: 3rem;
      input[type="radio"] {
        margin: 0 0.3rem 0 1rem;
      }
    }

    td.comments input {
      width: 95%;
      margin: 1rem 0;
    }

    tr {
      td {
        vertical-align: middle;
      }
    }

    input.days {
      width: 2.5rem;
    }

    input.hours {
      width: 1.8rem;
    }

    input.date {
      width: 8rem;
    }

    td.year > select {
      padding: 1.3px;
    }

    td.weekday {
      min-width: 6rem;
    }
  }

  table.absences,
  table.absence {
    td.warning {
      span {
        display: none;
        color: red;
      }

      &.shown span {
        display: inline-block;
      }
    }
  }

  table.vacation_days {
    border-collapse: collapse;

    th,
    td {
      width: 8rem;
      padding: 0.5rem;

      &:first-child {
        width: 10rem;
      }
      &:last-child {
        width: 3rem;
      }
      &.right {
        text-align: right;
      }

      span.hour-view,
      span.day-view {
        display: none;
        &.active {
          display: inline;
        }
      }
    }

    tbody {
      tr {
        &.last {
          border-bottom: 0.25rem solid $alternating-row-background-color;
        }

        &.hours {
          font-weight: bold;
          border-top: 0.1rem solid #000;
        }

        &.days {
          border-bottom: 0.1rem solid #000;
        }
      }

      td {
        vertical-align: inherit;

        &.odd {
          background-color: $alternating-row-background-color;
        }

        &.info {
          cursor: default;
          &:hover > div.tooltip {
            visibility: visible;
            margin-top: 1rem;
            margin-left: 8.2rem;
          }
          span {
            border-bottom: 0.1rem dotted #000;
          }
          th,
          td {
            width: auto;
          }
        }
      }

      & + p {
        margin-top: 0;
        font-style: italic;
      }
    }
  }
}
