.service-types-table {
  margin-top: 3rem;
  white-space: nowrap;

  &.dataTable {
    margin-top: 3rem;
  }

  .color-preview {
    width: 2rem;
    height: 2rem;
    border-radius: 50%
  }
}
