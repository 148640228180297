body.new {
  table.admin.skewed {
    padding-top: 8rem;
    th {
      padding: 0.8rem;
      transform: rotate(-90deg);
      text-align: left;
      font-weight: normal;
    }
    th, td {
      display: block;
      float: left;
      width: 1.8rem;
      padding: 0.3rem;
      &:first-child {
        width: 22.4rem;
      }
    }
  }
}
