input.colorpicker {
  width: 20px;
  color: transparent;
}

table.team {
  .selectize-control {
    min-width: 240px;
    .selectize-input {
    }
  }
  table#memberships {
    td {
      vertical-align: middle;
      line-height: 0;
      padding-bottom: 5px;
      img {
        margin-top: 0px !important;
      }
    }
  }
}
