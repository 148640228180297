.mfp-inline-holder .mfp-content {
  width: auto;
  max-width: 90vw;
  border-radius: 0.5rem;
  background-color: white;

  .mfp-close {
    margin: 1rem;
  }

  header {
    padding: 2rem 3rem;
    background-color: #EEE;
    border-radius: 0.5rem 0.5rem 0 0;

    h1 {
      margin: 0;
      color: #333;
      font-size: 1.8rem;
    }
  }

  .content {
    padding: 3rem;
    font-size: 1.3rem;

    p {
      margin-top: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  form {
    .field-group {
      padding-bottom: 0.5rem;
    }

    label {
      width: 15rem;
      font-weight: 500;
    }

    .time-input {
      display: inline-block;

      input {
        width: 6rem;
      }
    }

    .selectize-control {
      display: inline-block;
      position: relative;
      top: 1rem;
      width: 30rem;
    }
  }

  footer {
    padding: 2rem 2rem 3rem;
    text-align: center;

    a.button {
      margin: 0 0.4rem;
      padding: 1rem 1.6rem;
      font-size: 1.4rem;
      font-weight: normal;

      &.cancel, &.continue {
        padding: 1.2rem 1.6rem 1rem;
        background-color: #E5E5E5;
        border: 0.1rem solid #E5E5E5;
        font-size: 1.2rem;
      }
    }
  }

  .applicable-surcharge-popup & {
    .field-group:first-of-type {
      margin-top: 3rem;
    }

    i {
      margin-left: 1rem;
      color: #ccc;
    }

    span.red {
      color: #D8232A;
    }
  }
}
