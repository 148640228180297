.reporting-gross-margin {
  h1 {
    margin-bottom: 2.5rem;
    font-weight: normal;
  }

  .charts-toggle {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .toggle-box {
      display: flex;

      > div {
        margin-left: 1rem;
        padding: 0.5rem 1rem;
        border: 0.1rem solid $medium-background-color;
        border-radius: 0.5rem;
        opacity: 0.7;
        transition: 0.15s opacity ease-in-out;
        cursor: pointer;

        i {
          margin-right: 0.4rem;
        }

        &.active {
          opacity: 1;
          background-color: $medium-background-color;
        }
      }
    }
  }

  .gross-margin-chart {
    display: none;
    &.active {
      display: block;
    }
  }

  .total-gross-margin {
    max-width: 135rem;
    margin: 0 0.5rem 3.5rem;
    padding: 1.5rem 2rem;
    background-color: white;
    box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.1);

    .summary {
      display: flex;
      align-items: center;

      > i {
        margin-right: 2rem;
        color: $approval-border-color;
        font-size: 1.8rem;
      }
    }

    .global-chart {
      margin-top: 3rem;
    }

    .values-container {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      .text {
        p,
        h3 {
          margin: 0;
        }

        h3 {
          margin-bottom: 0.2rem;
        }

        p {
          opacity: 0.4;
          font-weight: bold;
        }
      }

      .amounts {
        display: flex;
        align-items: center;
        margin-right: -0.4rem;

        &.good {
          div {
            background-color: rgba(198, 233, 160, 0.5);
            color: $approval-border-color;
          }
        }

        &.bad {
          div {
            background-color: rgba(255, 214, 216, 0.5);
            color: $warning-border-color;
          }
        }

        &.mwah {
          div {
            background-color: rgba(244, 232, 197, 0.5);
            color: $light-orange-font-color;
          }
        }

        div {
          padding: 0.8rem 2rem;
          font-size: 1.6rem;
          font-weight: 500;
          border-radius: 0.3rem;

          &.euro-amount {
            margin-right: 2rem;
            min-width: 7rem;
          }

          &.percentage-amount {
            text-align: center;
            min-width: 3.3rem;
          }
        }
      }
    }
  }

  .report {
    width: 100%;
    background-color: #f8f8f8;

    .header-wrapper {
      max-width: 140rem;

      .sorting-header {
        display: flex;
        white-space: nowrap;

        cursor: pointer;
        padding: 0.2rem 0.6rem 0;
        margin: 0.5rem;
        font-weight: bold;

        .header-item {
          text-decoration: none;
          text-align: right;
          color: #7C7C7C;

          margin-left: 0.5rem;
          padding: 1rem calc( 1.75rem - 8.125px ) 1rem 0.25rem;

          display: flex;
          flex-direction: row;
          align-items: center;

          &:first-of-type {
            flex-grow: 1;
            text-align: left;
            padding-left: 0.5rem;
            padding-right: calc( 1.25rem - 8.125px );
          }
          &:nth-child(2){
            padding-left: 0;
            padding-right: calc( 1.75rem - 8.125px );
          }
          &:nth-child(3){
            padding-left: 0;
            padding-right: calc( 2.8rem - 8.125px );
          }
          &:nth-child(4){
            padding-right: calc( 1.1rem - 8.125px );
            padding-left: 0.6rem;
          }
          &:last-of-type {
            min-width: 5.2rem;
            padding-right: 1.25rem;
            padding-left: 2.5rem;
            justify-content: flex-end;
          }

          .arrow-icons {
            color: #B3B3B3;
            display: flex;
            flex-direction: column;

            margin-left: 0.75rem;
            margin-bottom: 0.1rem;

            .selected{
              color: #333333;
            }

            .fas:first-of-type{
              margin-bottom: -1.25rem;
            }
          }
        }
      }
    }

    .customers {
      max-width: 140rem;
      margin-bottom: 3rem;

      .customer {
        position: relative;
        margin: 0.5rem;
        background-color: white;
        border-bottom: 0.1rem solid transparent;
        border-radius: 0.3rem;
        box-shadow: 0.3rem 0.3rem 2rem rgba(0, 0, 0, 0.1);

        &.loading .contact-information {
          cursor: wait;
        }
        &.collapsed {
          &:hover {
            background-color: $grey-background-color;
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
          }

          .report-container,
          .exception-container {
            display: none;
          }
        }

        .report-container {
          padding: 2rem;

          .income,
          .costs {
            &.collapsed {
              .fa-angle-down {
                display: none;
              }
              .fa-angle-right {
                display: inline-block;
              }
              .table-container {
                height: 0;
                overflow: hidden;
              }
            }
          }

          .income-expand,
          .costs-expand {
            display: flex;
            justify-content: space-between;
            padding: 0.8rem 1rem;
            cursor: pointer;

            div:first-child {
              font-weight: bold;
            }

            i {
              width: 2rem;
            }

            .fa-angle-down {
              display: inline-block;
            }
            .fa-angle-right {
              display: none;
            }
          }
        }

        .contact-information {
          padding: 0.2rem 0.6rem 0;

          > div {
            padding: 1rem;
            &:first-of-type {
              flex-grow: 1;
            }
            &:not(:first-of-type) {
              margin-left: 1rem;
              text-align: center;
            }
            &.with-divider {
              padding-right: 2rem;
              border-right: 0.1rem solid $light-divider-color;
            }

            &.currency {
              text-align: right;

              .positive {
                color: $approval-border-color;
              }

              .negative {
                color: $warning-border-color;
              }

              &.gross-margin {
                min-width: 9rem;
              }

              &.absences {
                min-width: 7rem;
              }
            }

            &.percentage {
              display: flex;
              align-items: center;

              .indicator {
                min-width: 7.3rem;
                padding: 0.6rem 0.8rem;
                border-radius: 0.3rem;
                font-size: 1.3rem;
                line-height: 1.5rem;
                box-sizing: border-box;

                i {
                  margin-right: 0.5rem;
                }

                &.mwah {
                  background-color: rgba(244, 232, 197, 0.5);
                  color: $light-orange-font-color;
                }

                &.good {
                  background-color: rgba(198, 233, 160, 0.5);
                  color: $approval-border-color;
                }

                &.bad {
                  background-color: rgba(255, 214, 216, 0.5);
                  color: $warning-border-color;
                }
              }
            }

            span.company {
              font-size: 1.5rem;
            }
          }
        }

        .exception-container {
          text-align: center;

          .exception-message {
            display: none;
            margin-top: -5rem;
            padding-bottom: 5rem;
            font-size: 2rem;

            .double-spinner {
              display: block;
              margin: 0 auto;
              width: 15rem;
              height: 15rem;

              &:after {
                width: 10rem;
                height: 10rem;
                margin: 2rem;
                border-width: 1rem;
                animation-duration: 2s;
                -webkit-animation-duration: 2s;
              }
            }
          }
        }

        .contact-information {
          display: flex;
          white-space: nowrap;
          cursor: pointer;

          .invoiced-box {
            display: flex;
            align-items: center;
            margin: 1.3rem 1rem;
            padding: 0.5rem;
            text-transform: uppercase;
            font-weight: bold;
            color: #79b22b;
            border: 0.2rem solid #9cd64c;
            border-radius: 0.3rem;

            i {
              margin-right: 0.5rem;
            }
          }

          > div {
            span {
              &:first-of-type {
                display: block;
                margin-bottom: 0.3rem;
                font-weight: bold;
              }
              &.bu,
              &.contact,
              i {
                opacity: 0.5;
                &.fa-cogs {
                  margin-right: 0.3rem;
                }
              }
              &.bu {
                font-weight: bold;
              }

              a {
                display: inline-block;
                width: 2rem;
                text-align: center;
                font-size: 1.2rem;

                &:hover {
                  color: $bouwens-primary;

                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .table-container {
    table {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      font-size: 1.3rem;

      thead {
        tr {
          th {
            padding: 0.8rem 1rem;
            border-bottom: 1px solid #aaaaaa;
            background-color: #f8f8f8;

            &.numeric,
            &:last-child {
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          border-top: 1px solid #eeeeee;

          &.employee {
            cursor: pointer;

            i {
              width: 2rem;
            }

            .fa-angle-down {
              display: inline-block;
            }
            .fa-angle-right {
              display: none;
            }

            .percentage {
              width: 100%;
              text-align: right;
              margin-right: 5%;
              color: #bbbbbb;
            }

            &.collapsed {
              .fa-angle-down {
                display: none;
              }
              .fa-angle-right {
                display: inline-block;
              }
            }

            .hours {
              display: flex;
              width: 15rem;
              align-items: center;

              .percentage {
                width: 100%;
                text-align: right;
                margin-right: 5%;
                color: #bbbbbb;
              }
            }

            .edit {
              text-decoration-style: dashed;
              vertical-align: 0.1rem;
              font-size: 1.1rem;
            }
          }

          &.totals {
            border-top: 1px solid #aaaaaa;
            background-color: white;

            td {
              padding-bottom: 0.8rem;
              font-weight: bold;
            }
          }

          &:first-child {
            td {
              padding-top: 0.8rem;
            }
          }
          &:nth-child(4n-1) {
            background-color: #f8f8f8;
          }

          &.employee-details {
            display: none;

            .details-container {
              display: flex;
              justify-content: space-between;
              padding-left: 2.2rem;
              text-align: left;
              font-weight: normal;

              .category-block {
                padding: 1.5rem 3rem 2rem;
                margin: 1.5rem 1.5rem 1.5rem 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
                background: white;
                &:last-of-type {
                  margin-right: 0;
                }

                h3 {
                  margin: 1rem 0;
                  font-size: 1.6rem;
                }

                .costs {
                  .cost {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    line-height: 1.3;

                    span:first-of-type {
                      white-space: normal;
                    }

                    span.numeric {
                      margin-left: 1rem;
                    }
                  }
                }
                &:last-of-type {
                  .costs .cost span:first-of-type {
                    white-space: nowrap;
                  }
                }

                .total {
                  flex-grow: 1;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-end;
                  font-weight: bold;

                  .weighted {
                    font-weight: normal;
                    margin-right: 1rem;
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          td {
            padding: 0.4rem 1rem;

            &.numeric,
            &:last-child {
              text-align: right;
            }

            &:last-child {
              font-weight: bold;
            }
          }
        }
      }
    }

    .no-results {
      margin-left: 3rem;
    }
  }
}

span.greentag {
  border-color: #bbee55 #7cbb00 #7cbb00 #bbee55;
  background-color: #95e100;
}

span.purpletag {
  border-color: #dbadff #b171e4 #b171e4 #dbadff;
  background-color: #c07df5;
}

.export-to-excel-right {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
}

#picker {
  background-image: url("images/caret-down-solid.svg");
  background-repeat: no-repeat;
  background-position: right 1rem top 50%, 0 0;
  background-size: 1rem auto, 100%;
  padding-right: 3rem;
  text-indent: 1px;
  text-overflow: "";
  overflow: hidden;
  outline: none;

  &.arrow-up{
    background-image: url("images/caret-up-solid.svg");
  }
}
