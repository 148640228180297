.invoicing-overview .assignment-container {
  table.assignments {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    font-size: 1.3rem;

    &.kilometers {
      white-space: normal;

      td {
        line-height: 1.9rem;

        &.has-popover {
          padding-top: 0.2rem;
        }

        &.distance {
          i {
            position: relative;
            top: -0.2rem;
            &.deficit {
              color: $bouwens-primary;
            }
          }

          a.button {
            margin-top: 0.5rem;
          }
        }
      }

      .editable-time-range {
        padding-top: 0.3rem;
      }

      .with-input input:not([type='checkbox']) {
        padding-top: 0.4rem;
      }

      .hidden {
        display: none;
      }
    }

    tbody tr {
      height: 2.1rem;
      &:not(:last-child):not(:nth-last-child(2)) {
        border-bottom: 0.1rem solid rgba(black, 0.1);
        &.last-of-date {
          border-bottom: 0.1rem solid rgba(black, 0.4);
        }
      }
      &:first-child,
      &:last-child {
        border-top: 0.1rem solid rgba(black, 0.4);
      }
      &.from-blueprint {
        td {
          span.label {
            background-color: #2d89f5;
          }
        }
      }
      &.to-be-credited {
        td {
          span.label {
            background-color: black;
          }
        }
      }
      &.cancelled-with-charge {
        td {
          span.label {
            background-color: #802CC2;
          }
        }
      }
      &.outside-contract {
        td {
          span.label {
            background-color: #d8232a;
          }
        }
      }
      &.changes-from-blueprint {
        td {
          span.label {
            background-color: #2d89f5;
            &.changed {
              background-color: #f59a2d;
            }
          }
        }
      }
      &.weekend {
        td:first-child {
          position: relative;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0.3rem;
            background-color: red;
          }
        }
      }
      &:not(.invoiced) {
        &.assignment {
          cursor: pointer;
          &:not(.selected) {
            opacity: 0.3;
          }
        }
        &.from-blueprint {
          @include invoicing-row-color(#ecf6ff);
        }
        &.to-be-credited {
          @include invoicing-row-color(#cccccc);
        }
        &.cancelled-with-charge {
          @include invoicing-row-color(#f1e2fc);
        }
        &.outside-contract {
          @include invoicing-row-color(#fff0f1);
        }
        &.changes-from-blueprint {
          @include invoicing-row-color(#fff7ec);
        }
      }
      &.approved {
        td.editable-time-range {
          cursor: not-allowed;
        }
      }
      &.invoiced {
        @include invoicing-row-color(#edf5e3, 3);
      }

      &:not(.invoiced) td.change-approval:not(.has-popover):hover,
      &.invoiced td.send-invoice:hover {
        i,
        span.dismissed {
          display: none;
        }

        a.unapprove-assignment,
        a.undismiss-assignment {
          display: inline-block;
        }
      }
    }

    .undo-assignment-changes {
      position: absolute;
      top: 0.9rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      opacity: 0.5;
      cursor: pointer;
      z-index: 20;
      &:hover {
        opacity: 1;
      }
    }

    th,
    td {
      padding: 0.4rem 0.5rem;
      &.notes,
      &.change-approval,
      &.send-invoice {
        width: 7rem;
      }
      &.notes {
        padding-left: 2rem;
        text-align: center;

        i {
          display: inline-block;
          width: 1.6rem;
          color: black;
        }
      }
      &.surcharge-amount,
      &.custom-rate {
        position: relative;
        padding-right: 2rem;
      }
      &.has-popover,
      &.distance {
        i {
          font-size: 1.8rem;
          line-height: 1rem;
          vertical-align: sub;
          color: orange;
        }
      }
      &.change-approval,
      &.send-invoice {
        min-width: 6rem;
        text-align: center;

        .button {
          padding: 0 1rem;
          line-height: 1.4rem;
          font-size: 1rem;
          border: none;
        }

        a.unapprove-assignment,
        a.undismiss-assignment {
          display: none;
        }

        .hours-not-submitted,
        .has-expenses,
        .missing-billing-address {
          left: 0rem;
          margin-top: 0.2rem;
          color: orange;

          .tooltip-body {
            width: unset;
          }

          .button {
            width: calc(100% - 2.2rem);
            margin-top: 0.5rem;
            padding: 0.6rem 1.1rem;
          }

          .sms-reminder {
            background-color: #2d89f5;
          }
        }
      }

      &.change-approval {
        position: relative;

        .button {
          background-color: rgba(black, 0.12);
          &.reset {
            background: #d8232a;
          }
          &.reset:hover {
            background: darken(#d8232a, 5);
          }
          &.approve-assignment:hover {
            background-color: rgba(black, 0.16);
          }
        }
      }

      &.locations {
        padding: 0;
        position: relative;

        .double-spinner {
          display: none;
          margin-top: 0.5rem;
          width: 1.8rem;
          height: 1.8rem;

          &:after {
            width: 1.1rem;
            height: 1.1rem;
            border-width: 0.2rem;
          }
        }

        &.loading {
          .double-spinner {
            display: inline-block;
          }
        }

        > button {
          vertical-align: top;
          margin: 0.5rem;
          padding: 0.23rem 1rem;
          line-height: 1.4rem;
          font-size: 1rem;
          border: none;
          font-weight: bold;
          background-color: rgba(0, 0, 0, 0.16);
          border-radius: 0.4rem;
        }

        table {
          th {
            padding: 0;
          }

          tr:not(:last-of-type) {
            td {
              border-bottom: 0.1rem dashed #ccc;
            }
          }
        }
      }
      &.location {
        width: 15rem;
        min-width: 15rem;
        max-width: 15rem;
        word-break: break-word;
        white-space: normal;
      }
      &.distance {
        width: 8rem;
        min-width: 8rem;
        max-width: 8rem;
      }
      &.currency,
      &.time {
        width: 6rem;
        min-width: 6rem;
        max-width: 6rem;
      }

      span.label {
        display: inline-block;
        margin-right: -0.3rem;
        padding: 0 0.5rem;
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.3rem;
        border-radius: 0.3rem;
      }

      .fixed-price {
        position: absolute;
        right: -1rem;
      }
    }

    th {
      &.editable-time-range,
      &.custom-rate,
      &.surcharge-amount,
      &.change-approval {
        position: sticky;
      }
    }

    input:not([type='checkbox']) {
      border: 0.1rem solid transparent;
      outline: none;
      font-family: unset;
      font-size: unset;
      color: unset;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }
      &.money-amount {
        width: 6rem;
        text-align: right;
      }
      &.kilometers {
        width: 2.5rem;
        text-align: right;
      }
      &:disabled:hover {
        cursor: not-allowed;
      }
      &:disabled {
        background-color: unset;
      }
      &:not(:disabled):hover,
      &:focus {
        border-radius: 0.3rem;
        background-color: white !important;
        border: 0.1rem solid #eeeeee;
      }
      &.changed {
        border-radius: 0.3rem;
      }
    }

    .with-input {
      padding: 0 0.5rem;

      input:not([type='checkbox']) {
        display: inline-block;
        padding: 0.5rem 0.1rem 0.4rem;
      }
    }

    .editable-time-range {
      position: relative;
      padding: 0 1.4rem 0 0.5rem;
      width: 11rem;
      cursor: text;

      input {
        width: 1.6rem;
      }

      span {
        display: inline-block;
        margin: 0 -1rem 0 -1.1rem;

        &.separator {
          margin: 0 -0.7rem;
        }
      }
    }

    tr.totals-row {
      td {
        padding-top: 1rem;
        &:first-child {
          .totals {
            display: flex;

            > div {
              text-align: right;
              &:not(:first-child) {
                margin-left: 3rem;
              }

              span {
                display: block;
                &.totals-label {
                  font-weight: bold;
                }
              }
            }
          }
        }
        &.bulk-action {
          position: relative;
          text-align: center;
          &.with-spinner {
            padding-left: 4rem;
          }

          .double-spinner {
            position: absolute;
            top: -0.9rem;
            left: 1rem;
            &:after {
              width: 1.6rem;
              height: 1.6rem;
            }
          }

          a.button {
            padding: 0.3rem 0.5rem;
          }
        }
      }
    }
  }

  .static-tooltip-icon {
    strong {
      letter-spacing: 0.6px;
    }

    .tooltip-body {
      padding-top: 1rem;

      .arrow {
        top: 0.4rem;
        left: calc(50% + 0.4rem);
      }

      .tooltip-content {
        p {
          line-height: 1.8rem;
        }
      }
    }
  }

  span.greentag {
    border-color: #bbee55 #7cbb00 #7cbb00 #bbee55;
    background-color: #95e100;
  }

  span.purpletag {
    border-color: #dbadff #b171e4 #b171e4 #dbadff;
    background-color: #c07df5;
  }
}
