.with-filter-bar {
  .filter-bar-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 0.5rem;
    max-width: 138.5rem;
  }

  .filter-bar {
    display: flex;
    justify-content: flex-start;

    .filter {
      min-width: 5.1rem;
      margin: 0 3rem 3rem 0;
      &.large {
        width: 25rem;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        width: auto;
      }

      label {
        display: block;
        margin-bottom: 0.8rem;
        font-weight: bold;
      }

      .selectize-input,
      .selectize-dropdown {
        font-size: 12px;

        &.not-full .item {
          padding: 0;
          border: none;
          background: none;
          text-shadow: none;
          box-shadow: none;
          color: unset;
        }
      }
    }
  }
}
