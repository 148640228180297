@import "../../shared/colors";

@mixin highlight($color) {
  &.highlight {
    background-color: scale-color($color, $lightness: 50%);
    border-color: scale-color($color, $lightness: 50%);
  }
}

div.vacations {
  h2 {
    margin-bottom: 0.2em;
  }

  div.employees {
    display: flex;
    flex-wrap: wrap;

    h2 {
      flex-basis: 100%;
    }

    ul {
      margin: 0.5rem 4.8rem 1.6rem 0;
      padding: 0;
      list-style: none;
      line-height: 1.9rem;
    }
  }

  table.table-employees {
    width: 100%;
    border-collapse: collapse;

    td {
      height: 2.68em;
      padding-left: 0.3em;
      vertical-align: middle;
    }

    th {
        height: 2.68em;
        padding-left: 0.3em;
        vertical-align: middle;
    }

    tr:nth-child(odd) {
      background-color: #F3F3F3;
    }
  }

  table:not(.table-employees) {
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 2em;

    td {
      padding-left: 0.3em;
    }

    thead tr {
      td {
        &:not(:first-child) {
          text-align: center;
        }
      }
    }

    tbody tr {
      width: 900px;

      &:nth-child(odd) {
        background-color: #efefef;

        td.highlight {
          background-color: #f6f6f6;
        }
      }

      &:nth-child(even) {
        background-color: #f7f8f9;

        td.highlight {
          background-color: #fcfcfc;
        }
      }

      td {
        width: 12px;
        border-left: solid 1px #e1e1e1;
        border-right: solid 1px #e1e1e1;

        &:last-child {
          border-right: none !important;
        }

        &:first-child {
          width: 200px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          cursor: pointer
        }

        &.weekend {
          background-color: #e7e7e7;
          border-left: solid 1px #f1f1f1;
          border-right: solid 1px #f1f1f1;
          @include highlight(#e7e7e7)
        }

        &.vacation {
          background-color: $vacation_color;
          border-left: solid 1px $vacation_color;
          border-right: solid 1px $vacation_color;
          @include highlight($vacation_color)
        }

        &.vacation_request {
          background-color: $vacation_request_color;
          border-left: solid 1px $vacation_request_color;
          border-right: solid 1px $vacation_request_color;
          @include highlight($vacation_request_color)
        }

        &.special_leave,
        &.unpaid_leave {
          background-color: $special_leave_color;
          border-left: solid 1px $special_leave_color;
          border-right: solid 1px $special_leave_color;
          @include highlight($special_leave_color)
        }

        &.illness {
          background-color: $illness_color;
          border-left: solid 1px $illness_color;
          border-right: solid 1px $illness_color;
          @include highlight($illness_color)
        }

        &.roster_free {
          background-color: $roster_free_color;
          border-left: solid 1px $roster_free_color;
          border-right: solid 1px $roster_free_color;
          @include highlight($roster_free_color)
        }

        &.public_holiday {
          background-color: $public_holiday_color;
          border-left: solid 1px $public_holiday_color;
          border-right: solid 1px $public_holiday_color;
          @include highlight($public_holiday_color)
        }

        a.approve {
          color: $vacation_request_text_color;
          white-space: nowrap;
          position: absolute;
        }
      }
    }
  }

  div.legend {
    margin: 2em 0;

    div {
      display: inline-block;
      margin-right: 4em;
      font-weight: bold;

      &::before {
        content: " ";
        width: 8px;
        height: 8px;
        display: inline-block;
        margin: auto 0.8em auto 0;
      }

      &.illness::before {
        background-color: $illness_color;
      }

      &.vacation::before {
        background-color: $vacation_color;
      }

      &.vacation_request::before {
        background-color: $vacation_request_color;
      }

      &.special_leave::before,
      &.unpaid_leave::before {
        background-color: $special_leave_color;
      }

      &.roster_free::before {
        background-color: $roster_free_color;
      }

      &.public_holiday::before {
        background-color: $public_holiday_color;
      }
    }
  }
}
