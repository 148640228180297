.listing {
  .results.employees {
    .contact-details-container,
    .employment-details-container,
    .employment-details,
    .employment-links {
      padding: 1.6rem;
    }

    .employment-links {
      margin-top: 0.8rem;

      a.button {
        padding: 0.3rem 3.2rem;
      }

      .assignments-button.loading,
      .teams-button.loading,
      .portal-views-button.loading,
      .contracts-button.loading {
        cursor: wait;
      }
    }

    .tooltip-body {
      width: 34rem;

      .arrow {
        left: 50%;
      }
    }

    .actions {
      text-align: right;

      a {
        display: block;
      }
    }

    .employee-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .contact-details-container,
      .employment-details-container,
      .notes-container {
        background: white;
        box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
      }

      .contact-details-container,
      .employment-details-container {
        display: flex;
        padding: 0 1.6rem;
        justify-content: space-between;
        margin: 1.6rem;
        flex-grow: 1;
      }

      .contact-details,
      .employment-details,
      .employee-actions {
        h2 {
          font-size: 17px;
          font-weight: bold;
        }

        .fieldset {
          margin: 1rem 0;
          white-space: nowrap;

          label:not(.toggle) {
            display: inline-block;
            font-weight: bold;
          }
        }

        td.label {
          padding-right: 3.2rem;
          font-weight: bold;
        }

        td {
          padding: 0.5rem 0;
          overflow-wrap: break-word;

          &.service-type-container {
            display: flex;

            a.edit-service-type {
              margin-left: 1rem;
              text-decoration: underline;
              cursor: pointer;
              color: #2d89f5;
            }
          }
        }
      }

      .contact-details-container {
        .profile-picture {
          padding: 1.6rem;
          min-width: 12rem;
          text-align: center;

          .picture {
            display: inline-block;
            position: relative;
            margin-top: 1.2rem;
            margin-bottom: 1.6rem;
            height: 100px;
            width: 100px;
            text-align: left;

            img {
              object-fit: cover;
              background-color: white;
            }

            &:before {
              content: " ";
              display: inline-block;
              background-size: cover;
              background-image: url("images/profile-picture.png");
            }

            &:before,
            img {
              position: absolute;
              height: 100px;
              width: 100px;
              border-radius: 100%;
              box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
            }
          }
        }

        .contact-details {
          padding: 1.6rem;

          label {
            width: 11.2rem;
          }
        }

        .employee-actions {
          padding: 1.6rem;
          margin-top: 4.2rem;
          margin-left: auto;
          white-space: nowrap;

          label:not(.toggle) {
            width: 9.6rem;
          }
        }
      }
    }
  }
}

#assignment-container,
#contract-container {
  .content-loading {
    display: none;
  }

  .content {
    overflow: auto;
  }

  &.loading {
    min-width: 856px;
    min-height: 24rem;

    .content {
      display: none;
    }

    .content-loading {
      display: block;
      text-align: center;
      margin: 16rem 0 3.2rem;
      font-size: 22px;
    }

    .double-spinner {
      display: block;
      margin: 0 auto;
    }
  }

  p,
  h1 {
    margin-left: 1.6rem;
  }

  table {
    border-collapse: collapse;
    min-width: 80rem;

    th {
      position: sticky;
    }

    td,
    th {
      &:first-child {
        padding-left: 1.6rem;
      }

      &:last-child {
        padding-right: 1.6rem;
      }
    }
  }
}

#contract-container {
  .content {
    > table td,
    > table th {
      padding: 0.8rem 0.5rem;
    }

    .availabilities-row {
      > td {
        padding-top: 0;
      }

      .availability-container {
        padding: 0.5rem 1rem;
        border: 0.1rem solid $medium-divider-color;
        background: #f1f1f1;

        table {
          table-layout: fixed;
          width: 100rem;

          tr.current-week {
            font-weight: bold;
          }

          th, td {
            padding: 0.2rem 0.5rem;
            background: unset;
            text-align: center;
          }

          em {
            margin-left: 0.3rem;
            opacity: 0.5;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

#assignment-container {
  table td,
  table th {
    padding: 0.8rem 2.4rem;
    &:nth-last-child(-n + 2) {
      text-align: right;
    }
  }

  table {
    tr:nth-child(2n) {
      background: #f1f1f1;
    }
  }
}

#edit-service-type-modal {
  width: 45rem;
  // height: 15rem;

  h1 {
    margin-bottom: 2.5rem;
  }

  .mfp-close {
    margin-top: 2.3rem;
  }

  .double-spinner {
    position: absolute;
    z-index: 2;
    left: 20.5rem;
    top: 6rem;
  }

  .form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    form {
      .selectize-control {
        top: 0;
      }
    }

    .save-service-type {
      width: 100%;
      height: 1.7rem;
      margin-left: 1rem;
    }
  }
}
