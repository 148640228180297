html body.new .assignment-form {
  a.add-row.dark-grey-button {
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  a.add-common-row {
    margin-left: 1rem;
  }
}
