// There is similar styling for this selectionbox in daily_planning.scss, though that one is the old legacy css.
body.new {
  div.selections {

    &.wide {
      background: #e6e6e6;
    }

    div.calendar_holder {
      border-radius: 6px;
      background-color: #e5e5e5;
      padding: 2px;

      td {
        font-size: 9px;
      }
    }

    margin: 0.8rem 1.6rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div.monthselection {
      display: table-cell;
      width: 350px;
      padding: 0.2em 1em;

      p, select {
        display: inline-block;
        margin-right: 0.2em;
      }

      p {
        margin: 0.4em;
      }
    }

    div.monthselectionnew {
      display: grid;
      padding: 0.2em 0em;

      p, select {
        display: inline-block;
        margin-right: 0.2em;
        height: 3em;
        width: 8.6em;
        border-radius: 5px;
        border: 1px solid #DDDDDD;
      }

      select#month {
        width: 10.6em;
      }

      select#year {
        width: 8.7em;
      }

      select#business_unit {
        width: 10.6em;
      }

      p {
        margin: 0.4em;
      }
    }

    .legend {
      padding-left: 0.1em
    }

    .business-units-dropdown {
      position: relative;
      display: inline-block;
      width: 100%;
      padding-left: 35px;
      padding-right: 30px;
      box-sizing: border-box;
      height: 3em;
      line-height: 3em;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #DDDDDD;
      cursor: default;
      margin-right: 0.2em;
      text-align: left;
    }

    .dropdown-title {
      display: inline-block;
      vertical-align: middle;
    }

    .fa-angle-down {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .business-units-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      width: fit-content;
      padding: 12px 16px;
      white-space: nowrap;
      z-index: 999;
      left: 0;

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button, span {
          cursor: pointer;
        }
      }
    }

    .business-unit {
      display: block;
    }

    .business-units-dropdown.open .business-units-content {
      display: block;
    }


    div.dateselection {
      float: left;
      width: 520px;
    }
  }

  .select-with-icon {
    position: relative;
    display: inline-block;
  }

  .select-with-icon {
    position: relative;
    display: inline-block;
  }

  .select-with-icon select {
    width: 100%;
    padding-left: 35px;
    padding-right: 30px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .select-with-icon .fa-calendar-alt {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .select-with-icon .fa-layer-group.alt {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 99999;
  }

  .select-with-icon .fa-angle-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }


}
