.datepicker-grid-pop-up {
  right: 3.2rem !important;
  top: 13.5rem !important;
  left: auto !important;
  border: #ffffff !important;
  padding: 1.5rem 0rem 2.2rem 2.2rem !important;
  -moz-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1) !important;

  .ui-datepicker-next {
    right: 2.2rem !important;
  }

  .arrow {
    position:absolute;
    width: 1rem;
    height: 1rem;
    border-left: auto;
    border-top: auto;
    background-color: white;
    transform:rotate(45deg);
    top: -0.3rem;
    left: auto;
    right: 5rem;
  }
}
