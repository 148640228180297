.selectize-contact, .selectize-company, .selectize-category {
  .selectize-dropdown-content {
    max-height: 500px;
  }

  .select-contact-option, .select-company-option, .selectize-category-option {
    border-bottom: 0.1rem solid #eee;

    .company {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .bu, .address {
      font-size: 0.9rem;
      opacity: 0.5;
    }

    .description {
      font-size: 1.1rem;
      opacity: 0.5;
    }
  }
}

.selectize.multiple {
  .items {
    .item {
      background-image: none;
      background: none;
      color: unset;
      text-shadow: none;
      border: none;
      box-shadow: none;
      padding: 0;
      cursor: unset;
      &.active {
        background-image: none !important;
        background-color: none !important;
        background: none !important;
        border: none;
        color: unset !important;
      }
      &:not(:last-of-type) {
        display: block;
      }
    }
  }
}
