.knowledge-base-admin {
  * {
    box-sizing: border-box;
  }

  > header {
    a.button {
      font-size: 1.4rem;
    }
  }

  .content-block {
    &.new-question {
      display: none;
    }

    header h3 {
      position: relative;
      margin-top: 0;

      i {
        position: absolute;
        right: 0;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }

    .alert {
      display: none;
    }

    .selectize-control {
      min-width: 25rem;
    }
  }

  .question input {
    width: 100%;
  }

  .mce-tinymce {
    width: inherit !important;

    &.invalid {
      border-width: 0.2rem !important;
    }
  }

  .category {
    h3 {
      margin-bottom: 1rem;
    }

    .new-category {
      display: none;
    }

    .input-group .button {
      align-self: flex-end;
      margin-bottom: 0.2rem;
      padding: 0.6rem 1.2rem;
      height: 3rem;
    }
  }

  .actions {
    margin-top: 1rem;
  }
}

.question-overview {
  padding-left: 4rem;
  ol {
    padding: 0;
    list-style-type: none;

    li {
      position: relative;

      .category:before {
        position: absolute;
        left: -4rem;
        width: 3rem;
        text-align: right;
      }

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      .question {
        margin: 1rem 0;
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  > ol {
    > li {
      counter-increment: root;
      font-size: 2rem;
      font-weight: bold;

      > .category {
        margin: 2rem 0 1rem;
        padding-top: 2rem;
        border-top: 0.1rem solid #eee;
        &:before {
          content: counter(root) ". ";
        }
      }

      > ol {
        counter-reset: subsection;

        > li {
          counter-increment: subsection;
          font-size: 1.6rem;
          .category {
            margin: 2rem 0 0.5rem;
            &:before {
              content: counter(root) "." counter(subsection) " ";
            }
          }
        }
      }
    }
  }
}
