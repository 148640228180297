.service-form {
  .employee-dropdown-labels, .select-employee-option {
    display: inline-flex;
    align-items: center;
  }

  .employee-dropdown-labels label,
  .select-employee-option > span {
    padding: 0 0.5rem;
    &.name, &.job, &.city {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.name {
      width: 18rem;
    }
    &.job {
      width: 16rem;
    }
    &.city {
      width: 11rem;
    }
    &.mobile {
      width: 8rem;
    }
    &.contract-hours-surplus,
    &.assignments-done {
      width: 3rem;
      text-align: right;
      &.closed {
        color: #ee1d27;
      }
      &.open {
        color: #00a658;
      }
    }
    &.availabilities {
      width: 10rem;
      text-align: left;
    }
  }

  .employee-dropdown-labels label:nth-child(2) {
    // Replicate the dropdown padding from the second label,
    // the first label should be in line with other input labels.
    padding-left: 0.8rem;
  }

  .selectize-employee {
    width: 82.5rem;
    white-space: nowrap;

    input#assignment_employee_id-selectized {
      transform: translateX(1.9rem);
    }

    .select-employee-option {
      > span {
        display: inline-block;
        position: relative;
        white-space: nowrap;

        &.name {
          padding-left: 0;

          > a {
            text-decoration: none;
            font-size: 1.2rem;
            margin-right: 0.3rem;
            &:hover {
              opacity: 0.8;
            }
          }

          > i {
            margin-left: 0.5rem;
            font-size: 1rem;
            &.not-preferred {
              color: #ff5625;
            }
            &.preferred {
              color: #ffd223;
            }
          }
        }
        &.job, &.city {
          font-size: 1.1rem;
        }
        &.availabilities {
          display: inline-flex;

          span.timeblock {
            height: 1rem;
            width: 1rem;
            margin: 0 0.1rem;
          }
        }
      }
    }

    &.selectize-dropdown {
      .select-employee-option {
        display: flex;
      }

      span.name a.employee-link {
        visibility: hidden;
      }
    }
  }
}
