.feedback-summary {
  position: relative;
  width: 883px;
  height: 80px;
  background-color: #EEEEEE;
  border-top: 3px solid #EEEEEE;
  border-radius: 7px;

  .average-score {
    position: absolute;
    top: 15px;
    left: 20px;
    font-family: Arial, sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 23px;
  }

  .average-description {
    position: absolute;
    top: 13px;
    left: 70px;
  }

  .response-rate {
    position: absolute;
    top: 36px;
    left: 70px;
  }

  .toggle {
    position: absolute;
    top: 7px;
    right: 20px;
  }
}

.feedback-results {
  position: relative;
  top: -5px;
  padding-bottom: 30px;
  border-bottom: 3px solid #EEEEEE;
  border-radius: 7px;
  background-color: #EEEEEE;
  width: 883px;

  table {
    width: 100%;
    padding: 0 20px;
    border: 0;
    border-collapse: collapse;

    td.header {
      font-weight: bold;
    }

    td.name {
      padding-left: 20px;
      width: 20%;
      vertical-align: middle;
    }

    td.score {
      width: 10%;
      vertical-align: middle;
    }

    td.remarks {
      width: 45%;
      vertical-align: middle;
    }

    td.date-sent {
      width: 15%;
      vertical-align: middle;
    }

    td.assignment {
      text-align: right;
      padding-right: 20px;
      width: 10%;
      vertical-align: middle;
    }

    td.assignment-header {
      padding-right: 20px;
    }

    td.name-header {
      padding-left: 20px;
    }

    tr {
      height: 30px;
    }

    tr:nth-child(even) {
      background-color: #F8F8F8;
    }
  }
}
