.listing {
  input[type=text] {
    width: 85%;
    flex-grow: 2;
    padding: 6px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  h1 {
    display: inline-block;
    font-weight: normal;
  }

  > a.grey-button {
    margin-left: 1.6rem;
  }

  .filter-bar {
    display: flex;
    justify-content: space-between;
  
    .filter {
      min-width: 5.1rem;
      width: 15%;
      &:last-child {
        width: 5%;
        margin-right: 0;
      }
      &:first-child {
        width: 25%;
        margin-left: 0;
      }
      margin: 0 0.8rem;
  
      label {
        display: block;
        margin-bottom: 0.8rem;
        font-weight: bold;
        white-space: nowrap;
      }
  
      .selectize-input {
        max-width: 30rem;
        white-space: nowrap;
      }
  
      .selectize-input,
      .selectize-dropdown {
        font-size: 12px;
      }

      .export-to-excel {
        margin-top: 2.5rem;
      }
    }
  }

  .results {
    margin: 2.4rem 0;

    h2.empty-result {
      margin-top: 11.2rem;
      text-align: center;
    }

    > table {
      width: 100%;
      border-collapse: collapse;

      > tbody > tr, > thead > tr {
        > td, > th {
          padding: 0.8rem 1.6rem;

          &:first-child {
            padding-left: 1.6rem;
          }

          &:last-child {
            padding-right: 1.6rem;
          }
        }
      }

      > tbody {
        > tr:nth-child(4n-3), > tr:nth-child(4n-2)  {
          background: #f8f8f8;
        }

        > tr.main {
          &:hover {
            cursor: pointer;
          }

          &.open {
            border-top: 2px solid white;
            background: #f8f8f8;
          }

          &.loading {
            cursor: wait;
          }
        }

        > tr.main:hover:not(.open) {
          background: #E0EEFE;
        }

        > tr.details {
          display: none;
          background: #f8f8f8;

          a.button {
            display: block;
            padding: 0.3rem 1.1rem;
            margin-bottom: 1.1rem;
            font-size: 12px;
          }
        }
      }
    }
  }

  table {
    border-collapse: collapse;

    tr {
      &.odd {
        background-color: $grey-background-color;
      }

      th, td {
        padding: 0.8rem 1.6rem;
      }
    }
  }
}
