.eblox-export-page {
  .fields {
    display: flex;
    align-items: center;

    > label {
      margin-right: 0.8rem;
    }

    #date {
      margin-right: 0.5rem;
      padding: 0.4rem;
    }

    #explain {
      margin-right: 0.8rem;
    }

    input[type='submit'] {
      margin-right: 1.5rem;
    }

    input[type='checkbox'] {
      cursor: pointer;
    }
  }

  .payroll-data {
    margin-top: 4rem;
    font-size: 1.7rem;

    > div {
      display: flex;
      padding: 0.4rem 0;
      border-bottom: 0.1rem solid grey;
      white-space: nowrap;

      > a, > span {
        text-decoration: none;
        display: block;
      }

      .employee {
        width: 25rem;
        color: #102494;
      }

      .date {
        width: 9rem;
        color: #0e8036;
      }

      .day {
        width: 12rem;
        color: #0e8036;
      }

      .activity {
        width: 42rem;
        color: #702d0b;
      }

      .hours {
        width: 8rem;
        color: #5f0b70;
        font-family: monospace;
      }

      .output {
        min-width: 22rem;
        flex-grow: 1;
        text-align: right;
        font-family: monospace;

        .employee-output {
          color: #102494;
        }

        .date-output {
          color: #0e8036;
        }

        .activity-output {
          color: #702d0b;
        }

        .hour-output {
          color: #5f0b70;
        }
      }
    }
  }
}
