.reporting-dashboard {
  .filter-bar {
    display: flex;
    justify-content: flex-start;

    .filter {
      min-width: 5.1rem;
      width: 15rem;
      margin: 0 0.8rem 3rem 0;
      &.small {
        width: 10rem;
      }

      &:first-child {
        width: 8rem;
        padding-right: 1.6rem;
        margin-left: 0;
        border-right: 1px solid #D8D8D8;
      }

      &:last-child {
        width: 10rem;
        margin-right: 0;
      }

      label {
        display: block;
        margin-bottom: 0.8rem;
        font-weight: bold;
      }

      .selectize-input {
        max-width: 30rem;
      }

      .selectize-input,
      .selectize-dropdown {
        font-size: 12px;

        &.not-full .item {
          padding: 0;
          border: none;
          background: none;
          text-shadow: none;
          box-shadow: none;
          color: unset;
        }
      }
    }

    .options {
      padding-top: 2.4rem;

      a.button {
        &.show-tables {
          display: none;
        }
      }
    }
  }

  .report {
    width: 100%;
    background-color: #F8F8F8;

    .tables {
      table {
        margin-bottom: 5rem;
        tr {
          th {
            font-size: 1.4rem;
            line-height: 1.8rem;
            text-align: center;
            font-weight: 500;
            background: #f8f8f8;

            &.name {
              width: 20rem;
              font-size: 1.8rem;
              font-weight: bold;
              line-height: 2.1rem;
              text-align: left;
            }

            &.target {
              text-align: right;
            }
          }

          td {
            width: 7rem;
            padding: 0.25rem;

            &.name {
              padding: 0;
              vertical-align: middle;
              white-space: nowrap;
            }

            input {
              width: 6rem;
              padding: 0.4rem 1rem;
              border: 0.1rem solid #CCCCCC;
              border-radius: 0.3rem;
              background-color: #FFFFFF;
              text-align: right;
              font-size: 1.3rem;
            }

            .value {
              width: 5rem;
              padding: 0.4rem 1rem;
              text-align: right;
              font-size: 1.3rem;
              line-height: 1.8rem;
              border-radius: 0.3rem;

              &.equal {
                color: #78B732;
                background-color: #e7efdb;
              }
              &.higher {
                color: #78B732;
                background-color: #cde7a7;
              }
              &.lower {
                color: #D8232A;
                background-color: #f9d8d9;
              }
              &.blank {
                background-color: #ebebeb;
              }
            }
          }
        }
      }
    }

    .graphs {
      display: none;
      flex-wrap: wrap;
      justify-content: flex-start;

      .graph {
        width: 65rem;
        margin: 0 5rem 5rem 0;

        h3 {
          display: flex;

          .options {
            position: relative;
            display: inline-block;
            height: 2.5rem;
            margin-left: 3rem;

            .metric-selector {
              position: absolute;
              top: -0.2rem;
              display: inline-block;
              white-space: nowrap;

              span {
                font-size: 1.3rem;
                font-weight: normal;
              }

              .selectize-control {
                display: inline-block;

                .selectize-input {
                  border: none;
                  background: none;
                  box-shadow: none;

                  .item {
                    font-weight: normal;
                  }
                }

                .selectize-dropdown {
                  border: none;
                  width: 25rem !important;
                }
              }
            }

            span.pill {
              padding: 0.5rem 1.5rem;
              color: #333333;
              font-size: 1.3rem;
              font-weight: normal;
              line-height: 2.4rem;
              white-space: nowrap;

              &:hover {
                cursor: pointer;
              }

              &.selected {
                border-radius: 1.25rem;
                background-color: lightgrey;
                font-weight: bold;
              }
            }
          }
        }

        .chart {
          position: relative;
          padding: 2rem 2rem 2rem 1rem;
          border-radius: 0.4rem;
          background-color: #FFFFFF;
          box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
}
