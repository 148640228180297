section .unassigned-planning {
  font-family: Roboto, sans-serif;

  .selections {
    float: right;
    .dateselection {
      width: 100%;
    }
  }

  .statistics {
    width: 40%;
    .usage-bar {
      position: relative;
      margin-bottom: 2rem;
      background-color: $dark-background-color;
      text-align: center;
      color: $contrast-font-color;
      z-index: 1;
      border-radius: 2rem;

      .usage-bar-text {
        position: relative;
        padding: 0.2rem;
        z-index: 3;
        font-weight: bold;
      }

      .usage {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        max-width: 100%;
        height: 100%;
        background: linear-gradient(180deg, $approval-border-gradient 0%, $approval-border-color 100%);
        border-radius: 2rem;
      }
    }
  }

  .unassigned-planning-container {
    margin-top: 15rem;
  }

  .datepicker-trigger {
    position: absolute;
    top: 4.2rem;
    right: 3.2rem;
    .date-input {
      position: relative;
      input {
        padding: 0.6rem;
        border-radius: 0.3rem;
        border: 0.1rem solid #ccc;
        font-size: 1.3rem;
      }
    }
    .date-input:after {
      content: '\f073';
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      opacity: 0.5;
      pointer-events: none;
      font-family: 'Font Awesome 5 Pro';
    }
  }
  .day-block {
    padding-bottom: 4rem;
    .row {
      display: flex;
      margin-top: -0.1rem;
      overflow: hidden;

      .planning-tooltip {
        .confirm-change-container .inputs {
          > a {
            margin-left: 0rem;
          }
          .input-field {
            display:none;
          }
        }
      }
      &.day-row {
        .day-name {
          font-size: 1.4rem;
          font-weight: bold;
          width: 25rem;
        }
        .bar {
          border: 0;
          .hour {
            background: none;
            &:nth-child(odd) {
              background: none;
            }
          }
        }
      }

      .company-name {
        position: relative;
        width: 25rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
        border: 0.1rem solid $light-divider-color;
        border-right: 0;
          .company-link {
            font-size: 1.3rem;
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            position: absolute;
            margin: 0.5rem 0rem 0rem 0.5rem;
            white-space: nowrap;
          }
      }

      .bar {
        display: flex;
        position: relative;
        flex-grow: 100;
        height: 2.6rem;
        border: 0.1rem solid $light-divider-color;

          .hour {
              width: 5%;
              height: 100%;
              background: white;
              color: #888;
              font-size: 1.3rem;
              &:nth-child(odd) {
                background: #f8f8f8;
                span {
                  background: none;
                }
              }
              &:nth-child(3n + 1) span {
                opacity: 1;
              }

              span {
                margin-left: -0.4rem;
                opacity: 0;
                transition: opacity 0.3s;
                @include wide-desktop() {
                  opacity: 1;
                }
              }
          }

          .break {
            position: absolute;
            pointer-events: none;
            padding: 0;
            height: 2.4rem;
            z-index: 3;
            background: rgba(white, 0.3);
          }

          .planning {
            display: flex;
            justify-content: space-between;
            position: absolute;
            height: 2.6rem;
            cursor: pointer;

            > span {
              display: inline-block;
              padding: 0.5rem 0.7rem;
              font-size: 1.2rem;
              white-space: nowrap;
              &.start {
                .name {
                  font-weight: bold;
                }
              }
              > *:first-child {
                font-weight: bold;
                text-decoration:  none;
              }
              a:hover {
                text-decoration: underline;
              }
            }
          }
      }
    }
  }
}
