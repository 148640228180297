.survey-summary {
  display: flex;
  width: 1200px;
  height: 80px;
  padding: 10px 0;
  background-color: #EEEEEE;
  border-top: 3px solid #EEEEEE;
  border-radius: 7px;

  .scores {
    display: flex;
    width: 300px;

    .score-card {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;
      padding-left: 20px;
      font-family: Arial, sans-serif;
      font-weight: bold;

      .average-score {
        font-size: 25px;
        line-height: 23px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: right;
    width: 100%;
    padding-right: 20px;
  }
}

.survey-results {
  // position: relative;
  // top: -5px;
  padding-bottom: 15px;
  border-bottom: 3px solid #EEEEEE;
  border-radius: 7px;
  background-color: #EEEEEE;
  width: 1200px;

  table {
    width: 100%;
    padding: 0 20px;
    border: 0;
    border-collapse: collapse;

    td {
      vertical-align: middle;

      &.centered {
        text-align: center;
      }

      &.right {
        text-align: right;
        padding-right: 20px;
      }

      &.left {
        padding-left: 20px;
      }

      &.bold {
        font-weight: bold;
      }

      &.customer {
        width: 20%;
      }

      &.location {
        width: 10%;
      }

      &.score {
        width: 10%;
      }

      &.quarter {
        width: 15%;
      }

      &.date-sent {
        width: 15%;
        white-space: nowrap;
      }
    }

    tr.rows {
      &:nth-child(odd) {
        background-color: #F8F8F8;
      }
    }
  }
}

.survey-details-popup {
  .content{
    max-width: 600px;

    h1 {
      margin-right: 15px;
    }

    table {
      td.label {
        min-width: 130px;
        padding-right: 3.2rem;
        font-weight: bold;
      }

      td {
        padding: 0.5rem 0;
        overflow-wrap: break-word;
      }
    }
  }
}
