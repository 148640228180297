.invoicing-overview-container {
  height: 100%;

  h1 {
    margin-bottom: -1rem;
    font-weight: normal;
  }

  .period-selector {
    text-align: right;

    .selectize-control {
      display: inline-block;
      width: 20rem;

      .selectize-input {
        padding-right: 2rem;
        border: none;
        background: none;
        box-shadow: none;
        &:before {
          display: none;
        }

        input {
          display: none !important;
        }

        > div {
          font-size: 1.6rem;
          font-weight: bold;
        }
      }

      .selectize-dropdown {
        margin-left: 5.3rem;
        margin-top: 0.4rem;
        width: 14rem !important;
        border: none;

        .selectize-dropdown-content {
          padding-right: 1rem;
        }
      }
    }
  }

  .invoicing-overview {
    height: calc(100% - 8.2rem);
    -webkit-font-smoothing: antialiased;

    .header-bar {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .filters {
      display: flex;
      margin-bottom: 3rem;

      .filter {
        &.hidden {
          display: none;
        }
      }

      label {
        margin-right: 1rem;
        font-weight: bold;
      }

      .selectize-control {
        display: inline-block;
        margin-right: 3rem;
        vertical-align: middle;
        &.bu-select {
          width: 29rem;
        }
        &.bt-select {
          width: 20rem;
        }
        &.status-select {
          width: 12rem;
        }

        .selectize-dropdown-content {
          max-height: 40rem;
        }
      }
    }

    .contact-list {
      .contact-container {
        margin: 0.5rem;
        position: relative;
        background-color: white;
        border-bottom: 0.1rem solid transparent;
        border-radius: 0.3rem;
        box-shadow: 0.3rem 0.3rem 2rem rgba(0, 0, 0, 0.1);
        &.loading .contact-information {
          cursor: wait;
        }
        &.collapsed {
          &:hover {
            background-color: $grey-background-color;
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
          }

          .contact-information {
            padding: 0.2rem 0.6rem 0;
            &.not-fully-approved {
              span.value {
                opacity: 0.4;
              }
            }

            > div {
              padding: 1rem;
              &:first-of-type {
                flex-grow: 1;
              }
              &:not(:first-of-type) {
                margin-left: 1rem;
                text-align: center;
              }
              &.with-divider {
                border-right: 0.3rem solid $grey-background-color;
                padding-right: 2rem;
              }
              &.hours {
                width: 6.5rem;
              }
              &.currency {
                width: 7rem;
                text-align: right;
              }

              span.company {
                font-size: 1.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }

          .assignment-container,
          .exception-container {
            display: none;
          }
        }
        &:not(.collapsed) {
          .refresh-row {
            position: absolute;
            top: -3.1rem;
            right: 24rem;
            opacity: 0.5;
            cursor: pointer;
            &:hover,
            &.loading {
              opacity: 1;
            }
            &.loading i {
              animation: double-spinner 1s infinite linear;
            }

            i {
              font-size: 1.6rem;
            }

            span {
              margin-left: 0.5rem;
            }
          }

          a.export {
            position: absolute;
            top: -3.5rem;
            right: 15.5rem;
          }

          .contact-information {
            padding: 2rem 2rem 0;

            .invoiced-box {
              margin: 0.6rem 2rem 0.6rem 0;
            }

            span.company {
              font-size: 1.8rem;
              display: flex;
              align-items: center;
            }

            span.venue-name {
              margin: 0;
              max-width: none !important;
            }

            .collapsed-info {
              display: none;
            }
          }
        }

        span.manager {
          display: inline;
          opacity: 0.5;
          font-size: 1.3rem;
          font-weight: 400;
        }
        .contact-information > div span:first-of-type span.contact {
          display: inline;
          opacity: 0.5;
          font-size: 1.3rem;
          font-weight: 400;
        }

        .contact-information > div span:first-of-type span.venue {
          display: flex !important;
          flex-direction: row;
          margin: 0;
          opacity: 0.5;
          font-size: 1.3rem;
          font-weight: 400;

          @media screen and (max-width: 1680px) {
            span.venue-name {
              max-width: 18rem;
              margin: 0;
              display: inline-block !important;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .exception-container {
          text-align: center;

          .exception-message {
            display: none;
            padding-bottom: 5rem;
            margin-top: -5rem;
            font-size: 2rem;

            .double-spinner {
              display: block;
              margin: 0 auto;
              width: 15rem;
              height: 15rem;
              &:after {
                width: 10rem;
                height: 10rem;
                margin: 2rem;
                border-width: 1rem;
                animation-duration: 2s;
                -webkit-animation-duration: 2s;
              }
            }
          }
        }

        .contact-information {
          display: flex;
          white-space: nowrap;
          cursor: pointer;

          .invoiced-box {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            margin: 1.3rem 1rem;
            text-transform: uppercase;
            font-weight: bold;
            color: #79b22b;
            border: 0.2rem solid #9cd64c;
            border-radius: 0.3rem;

            i {
              margin-right: 0.5rem;
            }
          }

          > div {
            > span {
              &:first-of-type {
                display: block;
                margin-bottom: 0.3rem;
                font-weight: bold;

                span.venue-name {
                  font-weight: normal;
                }
              }
              &.bu,
              &.contact,
              i {
                opacity: 0.5;
                &.fa-cogs {
                  margin-right: 0.3rem;
                }
              }
              &.bu {
                font-weight: bold;
              }

              a {
                display: inline-block;
                width: 2rem;
                text-align: center;
                font-size: 1.2rem;
                &:hover {
                  color: $bouwens-primary;

                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        .assignment-container {
          position: relative;
          padding: 1.5rem 2rem 3rem;

          .contract-details-button {
            position: absolute;
            top: -3.5rem;
            right: 2rem;

            .contract-details {
              z-index: 30;
              color: #333;

              header {
                padding: 1.5rem 2rem;
                &:not(:first-of-type) {
                  border-top: 0.1rem solid #aaa;
                }

                h4 {
                  margin: 0 0 0.5rem;
                  color: #333;
                  font-size: 1.5rem;
                }

                p.invoiceable {
                  font-weight: bold;

                  i {
                    margin-right: 0.3rem;
                  }
                }

                .edit-contract {
                  float: right;
                  font-size: 1.2rem;
                }
              }

              .services {
                padding: 1.5rem 5rem;
                margin: 0 -1rem;
                background-color: #f3f3f3;

                table {
                  width: 100%;

                  th,
                  td {
                    &:last-child {
                      width: 10rem;
                      text-align: right;
                    }
                  }

                  th {
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #aaa;
                  }

                  td {
                    padding: 0.5rem 0 0;
                  }
                }
              }

              div.remarks {
                padding: 1.5rem 2rem;

                p {
                  margin: 0;
                }
              }

              .documents {
                position: relative;
                padding: 1rem 2rem 1.5rem 2rem;
                &:before {
                  position: absolute;
                  content: '';
                  top: 0;
                  left: 2rem;
                  right: 2rem;
                  height: 0.1rem;
                  background-color: #aaa;
                }

                a {
                  display: block;
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
        }
      }
    }

    p.no-results,
    p.error-message {
      margin: 6rem;
      text-align: center;
      font-size: 2rem;
    }

    p.error-message {
      display: none;
    }
  }
}
