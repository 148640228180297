span.tag {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: #FFFFFF;
    font-size: 70%;
    font-weight: bold;
    padding: 0 1px;
    text-transform: uppercase;
}

span.redtag {
    background-color: #ff0000;
    border-color: #ff7070 #d40000 #d40000 #ff7070;
}

span.orangetag {
    background-color: #e27111;
    border-color: #ff7070 #d40000 #d40000 #ff7070;
}
