body.new {
  div.invoice_totals {
    table.admin {
      th, td {
        padding: 0 1em 0.4em 1em;
        &.num {
          white-space: nowrap;
        }
      }

      tr:last-child td {
        font-weight: bold;
      }
    }
  }
}
