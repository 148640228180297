table {
  th {
    z-index: 9;
    position: sticky;
    top: -0.1rem;
    background: white;
  }

  &.alternating {
    border-collapse: collapse;

    thead th,
    tbody td,
    tfoot td {
      padding: 0.5rem;
    }

    th {
      border: none;
      padding-right: 1.5rem;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: $grey-background-color;
      }

      &.link:hover {
        background-color: #ebebeb;
      }
    }
  }

  &.admin {
    border: 2px solid #ddd;
    background-color: $admin-table-color;

    th {
      background: $admin-table-color;
    }

    th,
    td {
      &.num {
        text-align: right;
      }
      &.spacer {
        width: 1.6rem;
      }
    }
  }

  &.datatable {
    &.no-footer {
      border-bottom: 0;
    }

    thead th {
      padding-right: 2rem;
      border-bottom: 0;
    }
  }

  &.list {
    a.billing-address {
      position: relative;
      color: transparent !important;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("images/dimes_on.png");
        width: 1.3rem;
        height: 1.3rem;
        content: " ";
      }
    }
  }

  &.compact-listing {
    margin-bottom: 3rem;
    border-collapse: collapse;

    tr {
      td {
        padding: 0.5rem;
        vertical-align: middle;

        input {
          padding: 0.3rem 0.6rem;
        }
      }

      &.background {
        background: #f8f8f8;
      }

      &.heading {
        td {
          padding-top: 4rem;
        }
      }

      &.columns {
        td {
          font-weight: bold;
        }
      }
    }
  }

  th.icon,
  td.icon {
    text-align: center;
  }

  th.date,
  th.currency,
  th.number,
  th.time {
    text-align: right;
  }

  td.date,
  td.currency,
  td.number {
    position: relative;
    font-family: "Noto Mono";
    font-size: 1.25rem;
    line-height: 1.9rem;
    letter-spacing: -0.5px;
    text-align: right;
    white-space: nowrap;
  }

  td.date {
    width: 12rem;
  }

  th.left,
  td.left {
    text-align: left;
  }

  tr.link {
    &:hover {
      cursor: pointer;
      background-color: #ebebeb;
    }
  }

  th {
    padding: 0.2em 0.5em 0.2em 0;
    text-align: left;
  }

  td {
    vertical-align: top;
    padding: 0.2em 0.5em 0.2em 0;
    line-height: $base-line-height;

    &.right {
      text-align: right;
    }

    img.login {
      border: 0;
      vertical-align: -20%;
      padding-right: 2px;
    }

    i.entry-icon {
      margin: 0 0.5rem;
    }
  }
}

// TODO: Use a generic class for this table styling
div.mutations,
div.hours-and-expenses {
  .filters {
    display: flex;
    margin-bottom: 1rem;

    .filter {
      min-width: 15rem;
      margin-right: 5rem;
      label {
        display: block;
        margin-bottom: 0.8rem;
        font-weight: bold;
      }
    }
  }

  .dataTables_filter {
    display: none;
  }

  #hours-and-expenses tbody tr {
    white-space: nowrap;
  }
}
