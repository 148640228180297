#assignment-status-overview {
  .filter-bar {
    width: 45rem;

    .year {
      width: 7rem;
    }

    .week, .period, .month {
      width: 12rem;
    }
  }

  table {
    margin-top: 3rem;

    td.actions {
      &.all-approved {
        input.approve {
          visibility: hidden;
        }
      }
      &.none-approved {
        input.reset {
          visibility: hidden;
        }
      }

      input[type=submit] {
        margin-right: 1rem;
        padding: 0.4rem 1rem;
        font-weight: normal;
      }
    }
  }
}
