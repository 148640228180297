span.sprite {
  background: url("images/sprite.png") no-repeat scroll 0 0 transparent;
  display: inline-block;
  &.document {
    background-position: -30px 0;
    height: 17px;
    width: 14px;
  }
  &.document-jpeg {
    background-position: -30px -30px;
    height: 17px;
    width: 14px;
  }
  &.document-pdf {
    background-position: 0 -30px;
    height: 17px;
    width: 14px;
  }
  &.document-word {
    background-position: -60px 0;
    height: 17px;
    width: 14px;
  }
  &.information {
    background-position: -120px -60px;
    height: 16px;
    width: 16px;
  }
  &.information-small {
    background-position: -150px -60px;
    height: 9px;
    width: 9px;
  }
  &.confirmation {
    background-position: 0px -60px;
    height: 16px;
    width: 18px;
  }
  &.specification {
    background-position: -30px -60px;
    height: 16px;
    width: 18px;
  }
  &.servicereport {
    background-position: -60px -60px;
    height: 16px;
    width: 18px;
  }
  &.key {
    background-position: -90px 0;
    height: 14px;
    width: 14px;
  }
  &.tooltip-small {
    background-position: -150px -60px;
    height: 9px;
    width: 9px;
  }
  &.billing {
    background-position: -150px -30px;
    height: 13px;
    width: 11px;
  }
  &.nobilling {
    background-position: -120px -30px;
    height: 13px;
    width: 11px;
  }
}
