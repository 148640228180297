span.badge {
  margin-left: 1rem;
  padding: 0.4rem 0.8rem;
  border-radius: 10rem;
  background-color: #d8232a;
  color: white;
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.4rem;
  text-align: center;
}
