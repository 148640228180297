/*

    WYSIWYG.CSS - Stylesheet for Voormedia Webmanager WYSIWYG editor.
    Copyright © 2003-2008 Voormedia. All rights reserved.
    Tel. +31 (0)70 390 7317 - E-mail: info@voormedia.com

    WWW.VOORMEDIA.COM

*/

.tinyMCEarea {
  a { color: #526bb2 !important; /* FF requires a important here */ }
  ul { margin: 0; padding: 0; }
  li { margin: 0 0 8px 20px; }
}
