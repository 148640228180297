#hours-summary-overview, #absence-summary-overview {
  #hours-summaries-results, #absence-summaries-results {
    padding: 3.2rem;
    margin: 0 -3.2rem -3.2rem;
    background-color: $grey-background-color;
    &.compact {
      table.hours-summary, table.absence-summary {
        width: unset !important;
        margin: 0;

        .additional-data {
          display: none;
        }
      }
    }
  }

  .table-container {
    display: inline-block;
  }

  table.hours-summary, table.absence-summary {
    width: auto;
    margin: 0;
    white-space: nowrap;

    i.fa-exclamation-triangle {
      color: $light-orange-font-color;
    }

    td {
      &.red {
        color: #ee1d27;
      }
      &.green {
        color: #00a658;
      }
    }

    td.hidden, th.hidden {
      display: none;
    }

    thead {
      tr {
        border-bottom: 0.1rem solid $medium-divider-color;
      }

      th {
        background: $grey-background-color;
        padding-right: 2rem;
        border-bottom: 0;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: white;
        }
        &:nth-child(even) {
          background-color: unset;
        }

        td {
          &.left-in-days {
            text-align: left;
            font-size: 1.1rem;
          }

          .teams {
            display: block;
          }

          .job_name {
            display: block;
          }
        }
      }
    }

    tfoot {
      tr {
        border-top: 0.1rem solid $medium-divider-color;
        background: none;
      }
    }
  }

  #hours-summaries-results, #absence-summaries-results {
    &.compact {
      table.hours-summary, table.absence-summary {
        width: unset !important;
        margin: 0;

        .additional-data {
          display: none;
        }
      }
    }
  }

  .filter-bar {
    display: flex;
    margin-bottom: 2rem;
    white-space: nowrap;

    .filter {
      &:not(:first-child) {
        margin-left: 1rem;
      }
      &:nth-child(2) {
        margin-left: 2rem;
      }
      &.year {
        padding-right: 2rem;
        border-right: 0.1rem solid $mediumlight-divider-color;

        .selectize-control {
          width: 8.6rem;
        }
      }
      &.week .selectize-control {
        width: 11rem;
      }
      &.period, &.month {
        .selectize-control {
          width: 12.1rem;
        }
      }
      &.absence-type {
        width: 12.5rem;
      }
      &.bu {
        width: 23rem;
      }
      &.team {
        width: 20rem;
      }
      &.job_title {
        width: 22.5rem;
      }

      label {
        margin: 0.5rem 0;
        display: block;
        font-weight: bold;
      }
    }

    .difference-only, .show_teams_and_job_title {
      margin-top: 3.1rem;
      margin-left: 2rem;
    }

    .refresh-result {
      margin-top: 2.5rem;
      margin-left: 1rem;
    }

    .date-input {
      position: relative;
      display: inline-block;
      &:after {
        content: '\f073';
        position: absolute;
        top: 0.8rem;
        right: 0.8rem;
        opacity: 0.5;
        pointer-events: none;
        font-family: 'Font Awesome 5 Pro';
      }

      input {
        width: 9.5rem;
      }
    }

    .datepicker {
      font-size: 1.3rem;
      padding: 0.6rem;
      border-radius: 0.3rem;
      border: 0.1rem solid #ccc;
    }
  }

  .show-results {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }
}
