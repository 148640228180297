body.new,
body.legacy {
  > header {
    height: 5.6rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &.nl {
      background: linear-gradient(167.36deg, #555555 0%, #333333 100%);
    }
    &.be {
      background-color: $receptel-primary;
    }

    .left,
    .right {
      display: flex;
      white-space: nowrap;
    }

    .title,
    .nav-link {
      display: inline-block;
      height: 100%;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 11.4rem;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 20px;
      color: white;
      font-weight: 400;
      padding: 0 1.6rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: -0.03rem;
    }

    .environment-info {
      font-family: monospace;
      display: flex;
      align-items: center;
      padding: 0 1.6rem;
      color: white;
      font-size: 2rem;
      opacity: 0.5;
    }

    .nav-link {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      &:hover,
      &.active {
        background: white;

        a,
        i {
          color: black;
        }

        i {
          opacity: 0.7;
        }
      }

      &:hover {
        cursor: pointer;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        padding: 0 1.6rem;
        font-family: $new-font-family;
        font-size: 15px;
        color: white;
        text-decoration: none;

        i {
          margin-right: 0.6rem;
          font-size: 1.5rem;

          &.icon-sign-out {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }

    &.development {
      background: linear-gradient(167.36deg, #396aa7 0%, #2b5696 100%);
    }

    &.acceptance {
      background-color: $receptel-secondary;
    }

    &.guido-rainbow {
      background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
      );
    }
  }
}
