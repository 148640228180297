.feedback-content {
  max-width: 38rem;
  margin: 0 auto;
  color: #212C37;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 22px;
  margin-top: 2rem;

  .logo {
    float: right;
  }

  .introduction {
    padding-top: 2.1rem;
  }

  form {

    .header-chauffeursdiensten {
      color: #3B5D7F;
    }

    .header-bouwensen {
      color: #262626;
    }

    .header {
      display: flex;
      margin-top: 2rem;

      span {
        width: 33%;
        font-weight: bold;

        &.left {
          margin-left: 1.7rem;
          text-align: left;
        }

        &.center {
          text-align: center;
        }

        &.right {
          margin-right: 1.7rem;
          text-align: right;
        }
      }
    }

    .input-chauffeursdiensten,
    .remarks-chauffeursdiensten {
      border-radius: 2px;
      background-color: #EBF0F6;

      label {
        color: #5F87B0;
      }

      p {
        color: #3B5D7F;
      }
    }

    .input-bouwensen,
    .remarks-bouwensen {
      border-radius: 2px;
      background-color: #F1F1F1;

      label {
        color: #565656;
      }

      p {
        color: #262626;
      }
    }

    .input {
      display: flex;
      margin-top: 0.5rem;
      padding: 0.7rem 1rem;

      .option {
        display: inline-block;
        text-align: center;
        width: 10%;

        input {
          border: 0;
        }

        label {
          display: block;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .remarks {
      padding-bottom: 1.3rem;

      p {
        margin-top: 0.6rem;
        font-weight: bold;
        padding: 0.8rem 1.7rem 0;
      }

      textarea {
        display: block;
        margin: 0 auto;
        width: 85%;
        border: 1px solid #D0D8E0;
        border-radius: 3px;
        box-shadow: inset 2px 2px 8px 0 rgba(0,0,0,0.04);
        padding: 1rem;
        color: rgba(33,44,55,0.64);
        font-size: 14px;
        resize: vertical;
      }
    }

    .button-chauffeursdiensten {
      background-color: #4D7CAD;
      border-color: #4D7CAD;

      &:hover {
        background-color: #6A9ACC;
      }
    }

    .button-bouwensen {
      background-color: #D8232A;
      border-color: #D8232A;

      &:hover {
        background-color: #F91C25;
      }
    }

    .button {
      float: right;
      margin-top: 1rem;
      padding: 0.8rem 1rem 0.5rem;
      border-radius: 2px;
      border-style: outset;
      border: none;
      border-bottom: 3px solid rgba(0,0,0,0.31);
      outline: none;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 1px;
      font-family: "Roboto", sans-serif;
      color: white;
    }
  }
}

.feedback-content-responded {
  margin-top: 5rem;
  text-align: center;
  color: #212C37;
  font-family: "Roboto", sans-serif;

  .logo {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    line-height: 22px;
  }

  p {
    font-size: 15px;
    margin-bottom: -0.7rem;
  }

  a.link-bouwensen {
    color: #D8232A;
  }

  a.link-chauffeursdiensten {
    color: #5894D5;
  }
}
