.assignment-form {
  > .alert.warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:before {
      background-position: -30px -123px;
    }

    .red-button {
      font-size: 1.2rem;
    }
  }

  > form.new {
    display: flex;
    color: $portal-body-font-color;

    .basic-info {
      min-width: 35rem;
      max-width: 45rem;
      padding-right: 4rem;

      .basic-info-section {
        &:not(:last-child) {
          padding-bottom: 2rem;
          border-bottom: 0.2rem solid #eee;
        }
        &:not(:first-child) {
          padding-top: 2rem;
        }

        h3 {
          margin: 0 0 1rem;
        }

        .static-tooltip-icon {
          float: right;

          .tooltip-body {
            width: unset;
            white-space: nowrap;

            td:first-child {
              min-width: 0;
            }
          }
        }

        .evaluation-sent {
          position: relative;
          padding: 2rem 3.9rem 0;
          &:before {
            position: absolute;
            top: 2rem;
            left: 0.4rem;
            content: '\f00c';
            color: $approval-font-color;
            font-size: 1.5rem;
            font-family: 'Font Awesome 5 Pro';
          }
        }

        .evaluation-days-field {
          input {
            width: 3rem;
          }

          a.button {
            float: right;
            margin-top: 1.1rem;
            margin-right: 0.8rem;
          }
        }

        .confirmation-log {
          margin: 0.5rem 0 1rem;

          .log-entry {
            margin-bottom: 0.7rem;
            line-height: 1.2rem;
            font-size: 1.1rem;
            opacity: 0.8;
          }
        }

        .note-group-title {
          margin-top: 2rem;
          margin-left: 4rem;
          font-weight: bold;
        }

        ul.notes {
          margin-top: 0.3rem;

          li {
            margin: 1rem 0;

            span.author {
              font-weight: bold;
            }
          }
        }
      }

      table {
        td {
          height: 3rem;
          vertical-align: middle;
          &:first-child {
            min-width: 10rem;
            font-weight: bold;
          }
          &:not(:first-child) {
            width: 100%;
          }

          .selectize-dropdown-content {
            max-height: 500px;
          }
        }
        &.confirmation {
          tr td:first-child {
            vertical-align: top;
            padding-top: 1.4rem;
          }
        }
      }

      .confirmation-box {
        display: flex;
        margin: 0.5rem 0;
        border: 0.1rem solid $medium-divider-color;
        border-radius: 0.4rem;

        input {
          padding-right: 1rem;
          flex-grow: 1;
          border: none;
        }

        a {
          padding: 0 1rem;
          line-height: 2.9rem;
          font-weight: bold;
          border-radius: 0 0.4rem 0.4rem 0;
          background-color: $medium-background-color;
          text-decoration: none;
          &:hover {
            background-color: darken($medium-background-color, 5%);
          }
          &.loading {
            cursor: wait;
            opacity: 0.3;
          }
          &.disabled {
            cursor: not-allowed;
            opacity: 0.3;
          }
        }
      }
    }

    .service-info {
      .service-form {
        display: flex;
        flex-wrap: wrap;

        .admin-location-form {
          tr.fields:first-of-type td.schedule-time {
            position: relative;
            &.arrival-time:after {
              position: absolute;
              top: 0.9rem;
              opacity: 0.7;
            }

            > * {
              visibility: hidden;
            }
          }

          tr.fields:first-of-type td.arrival-time:after {
            content: "Uses assignment start time";
          }
        }

        .form-block {
          width: 100%;
          margin-bottom: 1.5rem;
          padding: 2rem 2rem 1rem;
          background: $medium-background-color;
          border-radius: 1rem;

          &.hidden {
            display: none;
          }

          h3 {
            margin-top: 0;
          }

          .new-alert.critical {
            margin: 0;
          }

          .field-group {
            display: flex;
            flex-grow: 1;
            padding-bottom: 2rem;
            border: none;
            &.block {
              flex-basis: 100%;
            }

            .field {
              padding-right: 2rem;
              &.grow {
                flex-grow: 1;
              }
              &.without-label {
                margin-top: 2rem;
              }

              label:not(.checkbox-label) {
                display: inline-block;
                margin-bottom: 0.5rem;
                font-weight: bold;

                span.caption {
                  margin-left: 0.5rem;
                  opacity: 0.5;
                  font-size: 1.2rem;
                  font-weight: normal;
                  font-style: italic;
                }
              }

              input[type='text'] {
                display: block;
                border: none;
                &.timepicker,
                &.timefield {
                  width: 6rem;
                }
                &.datepicker {
                  width: 10rem;
                }
              }

              textarea.notes-textarea {
                resize: none;
                width: calc(100% - 2rem);
                min-width: 20rem;
                height: 5.7rem;
              }

              .selectize-control {
                min-width: 20rem;
              }

              &.checkbox {
                margin-top: 3.5rem;
                white-space: nowrap;
              }

              &#hourly:not(.active),
              &#fixed:not(.active) {
                display: none;
              }
            }

            i.fa-taxi {
              margin-top: 3.5rem;
              font-size: 2rem;
              margin-right: 2rem;

              .tooltip-body {
                padding: 2rem;
                width: 30rem;
                top: 3rem;
                left: -16.5rem;
                font-size: 1.3rem;
                line-height: 2.2rem;

                h3 {
                  margin: 0 0 1rem 0;
                }

                .outsourcing:not(:last-child) {
                  border-bottom: 0.1rem solid $light-divider-color;
                }
              }
            }
          }
        }

        table.surcharges {
          margin-bottom: 2rem;

          td {
            &.description {
              width: 22rem;

              i {
                margin-left: 0.5rem;
              }
            }

            &.percentage {
              width: 5rem;
              padding-right: 10rem;
              text-align: right;
            }

            i {
              color: #b8b8b8;
            }
          }
        }

        a.button.grey-button {
          background-color: #dadada;
          font-weight: 400;
        }
      }
    }

    .alert {
      margin: 0;
    }

    .actions {
      margin-top: 2rem;
      text-align: right;
      white-space: nowrap;

      a,
      input {
        padding: 1rem 1.5rem;
        margin: 0 0.5rem;
        border-radius: 0.6rem;
        white-space: nowrap;

        &.delete {
          margin-right: 1rem;
          font-weight: bold;
          text-decoration: none;
          opacity: 0.3;
        }
      }
    }
  }
}

.assignment-form.edit-form form {
  display: block;

  .basic-info {
    margin-bottom: 2rem;
  }
}
