div.alert,
div.error,
div.ok {
  &::before {
    content:"";
    position: absolute;
    background-image: url("images/sprite.png");
    background-repeat: no-repeat;
    background-position: -147px -57px;
    width: 15px;
    height: 15px;
    margin-left: -1.8em;
    margin-top: 0.2em;
  }
  display: inline-block;
  margin-top: 1em;
  & ~ & {
    margin-top: 0;
  }
  margin-bottom: 1em;
  padding: 0.5em 0.9em 0.5em 2.5em;
  border: 1px solid transparent;
  background-repeat: no-repeat;
  background-position: 0.8em 0.9em;
  line-height: 1.8em;
  background-color: #eee;
  border-color: #ddd;
  font-size: 12px;
  &.ok {
    &::before {
      background-position: 0 -120px;
    }
    background-color: #e4ffcb;
    border-color: #d3e6c2;
    color: #489500;
    a {
      color: #489500;
    }
  }
  &.warning {
    &::before {
      background-position: -30px -120px;
    }
    background-color: #ffedd6;
    border-color: #ffd59c;
    color: #ff9300;
    a {
      color: #ff9300;
    }
  }
  &.error {
    &::before {
      background-position: -60px -120px;
    }
    background-color: #fff0f0;
    border-color: #ffb9b9;
    color: #ff3a3a;
    a {
      color: #ff3a3a;
    }
    span.header {
      font-weight: bold;
    }
  }
  h3 {
    margin-top: 1px;
  }
  ul {
    padding: 0.3em 1.35em;
    margin: 0;
  }
}

div.new-alert{
  display: inline-block;
  margin-top: 1em;
  & ~ & {
    margin-top: 0;
  }
  margin-bottom: 1em;
  padding: 0.5em 0.9em 0.5em 0.9em;
  border: 1px solid transparent;
  line-height: 1.8em;
  background-color: #eee;
  border-color: #ddd;
  font-size: 12px;

  &::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    padding-right: 0.7rem;
  }

  span.header {
    font-weight: bold;
  }

  &.warning {
    background-color: #ffedd6;
    border-color: #ffd59c;
    color: #ff9300;

    &::before {
      content: "\f071";
      color: #ff9300;
    }

    a {
      color: #ff9300;
    }
  }

  &.critical {
    background-color: #fff0f0;
    border-color: #ffb9b9;
    color: #ff3a3a;

    &::before {
      content: "\f071";
      color: #ff3a3a;
    }

    a {
      color: #ff3a3a;
    }
  }

  &.hidden {
    display: none;
  }
}

span.new-alert {
  font-size: 11px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &.hidden {
    display: none;
  }
  &::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
  }
  &.critical {
    &::before {
      content: "\f071";
      color: #ff3a3a;
    }
  }
}

// OLD PHP SPECIFIC STYLING
div.alertmessage {
  div.alert {
    &::before {
      background-position: -30px -120px;
    }
    background-color: #ffedd6;
    border-color: #ffd59c;
    color: #ff9300;
    a {
      color: #ff9300;
    }
  }
}
