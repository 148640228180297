body.new .blueprint,
.planning-tooltip {
  input {
    padding: 6px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #ccc;

    &:focus{
      outline: none;
    }

    &:disabled {
      background-color: #FDFDFD;
      border-color: #DCDCDC;
      color: lightgrey;
    }
  }

  input[type='submit'] {
    padding: .7em 2.2em;
    background-color: #EEEEEE;
    border: 0;
    font-size: 15px;

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: #CCCCCC;
    }
  }

  input.timepicker {
    width: 6.4rem;

    &:after {
      content: "\64";
    }
  }
}
