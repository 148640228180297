section.rails-container {
  form.period-selections {
    display: flex;

    .selector {
      width: 15rem;
      margin-right: 2rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
    }
  }
}
