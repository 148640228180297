#invoices-container {
  min-width: 50rem;

  table {
    width: 100%;
  }
}

#contract {
  padding-bottom: 10rem;

  form.edit_contract {
    display: block;
  }

  .main-details {
    .column {
      .document {
        margin: 0.5rem 0;
        white-space: nowrap;
        color: $body-font-color;

        i.fa-file-alt {
          margin-right: 0.5rem;
          font-size: 1.6rem;
        }

        a {
          display: inline-block;
          max-width: 30rem;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: none;
        }

        i.fa-times-circle {
          margin-left: 0.5rem;
          opacity: 0.2;
          &:hover {
            opacity: 1;
          }
        }
      }

      .mandatory {
        margin-top: 2rem;
      }
    }

    .invoicing-details {
      position: relative;
      background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.03) 60%);

      .toggle {
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .invoices-button {
        position: absolute;
        right: 3rem;
        top: 2.7rem;
      }

      .automatic-invoicing-options {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        min-width: 47.2rem;

        .field-group.block {
          justify-content: flex-start;
          gap: 1rem;
        }

        &.hidden {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  .discount-details {
    margin-bottom: 4rem;
  }

  table#custom-rates,
  table#job-title-discounts {
    .selectize-input {
      min-width: 25rem;
    }
  }

  table#services,
  table#custom-rates,
  table#job-title-discounts {
    a.remove_nested_fields {
      margin-left: 0.5rem;
      line-height: 3rem;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    td.adjust-button {
      a.button {
        padding: 0.6rem 1.1rem;
      }
    }

    .hidden {
      visibility: hidden;
    }
  }

  table#services {
    width: 100%;

    thead tr, thead th {
      background: transparent;
    }

    tr.fields {
      td:first-child input {
        width: 12rem;
      }

      td:nth-child(2) input {
        width: 8rem;
      }
    }
  }
}

section.rails-container {
  table {
    td {
      &.expiring-soon {
        color: rgb(253, 146, 38);

        img {
          position: relative;
          top: 0.2rem;
          margin-left: 0.5rem;
        }
      }

      a {
        color: #333;
      }
    }
  }
}
