.planning-tooltip {
  display: none;
  position: absolute;
  z-index: 11;
  margin-bottom: 8rem;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.25);
  font-size: 11px; // Legacy, comply with daily planning styling
  &.read-only {
    a.change {
      pointer-events: none;
    }
  }

  .arrow {
    position: absolute;
    top: -0.64rem;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-bottom: 0.6rem solid #fff;
  }

  .assignment-details,
  .employee-container {
    padding: 0.8rem 2.4rem 3.2rem 2.4rem;
  }

  .assignment-details {
    display: flex;
    flex-direction: column;
    flex-grow: 6;
    min-width: 24rem;
    position: relative;
    background: linear-gradient(90deg, #ffffff 0%, #f5f5f5 100%);
    white-space: nowrap;
    overflow: hidden;

    h2 {
      margin-bottom: 1.6rem;
      padding-right: 5.6rem;
      font-size: 1.8rem;
    }

    .edit {
      position: absolute;
      right: 0;
      margin: 1.1rem 1.6rem 0 0;
    }

    .information-block {
      margin-bottom: 1.1rem;
      line-height: 1.6rem;

      span.label {
        display: inline-block;
        width: 7.5rem;
        text-align: right;
        font-weight: bold;
      }

      span.value {
        margin-left: 0.8rem;
      }
    }

    .assignment-actions {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      a {
        margin-left: 1rem;
      }
    }
  }

  .employee-container {
    flex-grow: 4;
    min-width: 35.2rem;
    background: #fff;

    h2 {
      font-size: 1.8rem;
    }

    .spinner-container,
    .alert.error:not(.absence) {
      display: none;
    }

    .alert.warning {
      max-width: 28rem; // IE
      max-width: fit-content;

      .red-button {
        margin: 0.5rem 0;
        padding: 0.3rem 1.5rem;
        font-size: 1.2rem;
      }
    }

    .absence {
      margin-top: 0;
      padding: 5px 10px 5px 25px;
    }

    &.loading {
      .spinner-container {
        display: block;
        margin: 8rem auto;

        .spinner {
          width: 25px;
          height: 20px;

          > div {
            width: 3px;
          }
        }
      }

      .employee-list,
      .actions,
      .confirm-change-container {
        display: none !important;
      }
    }

    .employee-list {
      span.availabilities {
        display: flex;
        height: 13px;
      }

      .new-alert {
        margin-top: 0;
      }

      label {
        margin-bottom: 0.7rem;
      }

      ul.availabilitylegend {
        padding-left: 1rem;
        margin: 0;
        flex-grow: 1;
        white-space: nowrap;
        font-size: 1.1rem;

        li {
          @include clearfix;
          margin: 0;
          padding: 0;
          list-style: none;
          line-height: 16px;
          padding: 2px 0;

          span.timeblock {
            margin-right: 5px;
          }
        }
      }

      ul.selection {
        margin: 0;
        padding: 0;
        min-width: 60%;
        flex-grow: 1;
        &.first {
          margin-bottom: 1.5rem;
        }
        > li {
          @include clearfix;
          margin: 0;
          padding: 0;
          list-style: none;

          display: grid;
          grid-template-columns: 13rem 7rem 10.5rem 2.3rem 2.3rem;
          grid-gap: 0.5rem 1rem;

          label.contract-hours-surplus {
            text-align: right;
          }

          a {
            color: $body-font-color;
            cursor: pointer;
            text-decoration: none;
            line-height: 16px;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
            }

            .employee-block {
              overflow: hidden;
              text-overflow: ellipsis;

              .employeecolor {
                float: left;
                width: 15px;
                height: 15px;
                margin: 0 8px 0 0;
                background: black;
              }
            }
          }

          span.contract-hours-surplus {
            text-align: right;
            font-style: normal;
            &.closed {
              color: #ee1d27;
            }
            &.open {
              color: #00a658;
            }
          }

          &.flexible-in-team {
            font-style: italic;
          }
        }
      }

      span.timeblock {
        width: 7px;
        height: 7px;
        margin-left: 1px;
        display: inline-block;
        overflow: hidden;
      }
    }

    .actions {
      margin-top: 2.4rem;

      a:not(:first-child) {
        margin-left: 0.8rem;
      }
    }

    .confirm-change-container {
      display: none;
    }

    .confirm-change-container {
      .message {
        margin-bottom: 1.6rem;
      }

      .input-field {
        position: relative;
        width: 8.8rem;

        label {
          display: block;
          margin-bottom: 0.8rem;
          font-weight: bold;
        }

        textarea {
          margin-bottom: 1rem;
          height: 3rem;
          width: 15rem;
          font-size: 1.3rem;
          padding: 0.6rem;
          border-radius: 0.3rem;
          border: 0.1rem solid #ccc;

          &:focus {
            outline: none;
          }
        }
      }

      .inputs {
        display: flex;
        align-items: start;

        .input-field {
          input {
            display: block;
            font-size: 12px;
          }

          &:after {
            position: absolute;
            right: 2.2rem;
            top: 3rem;
            content: '\64';
            color: #bbb;
            font-family: 'bouwens-extranet';
          }
        }

        a {
          margin-top: auto;
          margin-left: 0.8rem;

          &.cancel {
            padding: 0.8rem 1.1rem;
            color: #aaa;
            font-style: italic;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .confirm-change-actions {
      margin-top: 2.4rem;
      text-align: right;

      a {
        margin-left: 0.8rem;
      }
    }

    &.confirm-change {
      .employee-list,
      .actions {
        display: none;
      }

      .confirm-change-actions,
      .confirm-change-container {
        display: block;
      }
    }
  }
}
