$editing-row-background-color: #E0EEFE;
$alternating-row-background-color: #f3f3f3;

body.new .blueprint-overview {
  font-size: 12px;

  h1 {
    font-size: 24px;
    display: flex;
    justify-content: space-between;

    a {
      font-size: 1.4rem;
    }
  }
}

body.new .blueprint-overview {
  table {
    tr {
      &.alt {
        background-color: $alternating-row-background-color;
      }

      th, td {
        padding: 10px;
        text-align: left;

        &.center {
          padding: 10px 15px;
          text-align: center;
        }

        &.right {
          text-align: right;
        }
      }
    }
    tbody {
      tr:hover {
        background-color: $editing-row-background-color;
        cursor: pointer;
      }
    }
  }
}

#create-blueprint-popup {
  label {
    display: block;
    font-weight: bold;
  }
}
