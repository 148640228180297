/*

 Stylesheet for Voormedia Content Management System.
 Copyright © 2003-2016 Voormedia. All rights reserved.

 WWW.VOORMEDIA.COM

*/

.php-container {
  /* GENERAL TAG STYLES */

  body,td,th { font-family: Roboto, Arial, Sans-serif; }
  h1,h2 { font-size: 130% !important; font-weight: 700; margin: 17px 0 11px 0; }
  h3,h6 { font-size: 100% !important; font-weight: 700; margin: 10px 0 4px 0; }
  h6 { margin: 0 0 4px 3px; }
  hr { color: #c4c4c4; background-color: #c4c4c4; height: 1px; }
  a { border: 0; color: #000; }
  a img { border: 0; }
  textarea { font-family: sans-serif; font-size: 110%; }
  form { display: inline; }
  th { text-align: left; font-weight: bold; }
  th a { color: #000; text-decoration: none; }
  td { vertical-align: top; }
  ul { margin: 0; padding: 0; }
  li { list-style-type: none; list-style: none; margin: 0; padding: 3px 0 5px 20px; background: url("images/navarrow.gif") no-repeat 5px 7px; }

  /* GLOBAL CLASS STYLES */

  img.inline_icon { vertical-align:bottom; }
  form.periodform { clear: left; display: block; margin: 0; padding: 6px 0 8px 6px; }
  form.perioddateform { display: block; margin: 0; padding: 4px 0 4px 6px; }
  span.inactive { text-decoration: line-through; background-color: #fe7; }
  .highlight { background: yellow; }

  body.header { background-color: #f2f2f2; }
  body.acceptance { background-color: #ffb09e; }
  body.preview { background-color: #dbffbb; }
  body.office { background-color: #dbffbb; }
  body.development { background-color: #feffbb; }
  body.header span.diskspace { color: #f00; }
  body.header div.date { position: absolute; width: 97%; text-align: right; margin: 42px 20px 0 0; }
  body.header div.title { position: absolute; padding: 18px 0 0 18px; font-weight: 600;}
  body.header div.title div.main-title { font-size: 2.1rem; font-weight: lighter; color: rgb(130,130,130); }
  body.header div.menu { white-space: nowrap; position: absolute; background-color: #E3E3E3; border-top: 1px solid #c4c4c4; border-bottom: 1px solid #c4c4c4; width: 100%; margin-top: 70px; padding: 1px 3px 0 215px; height: 26px; }
  body.header div.menu img { vertical-align: middle; border: 0; margin: 0 3px 0 0; }
  body.header div.menu a { padding: 0 3px 0 2px; margin: 1px 6px 0 0; float: left; border: 1px solid #E3E3E3; font-weight: bold; color: #000; text-decoration: none; line-height: 20px; height: 21px; }
  body.header div.menu a.active { border: 1px solid #A4A4A4; background-color: #ccc; }
  body.header div.menu a:hover { border: 1px solid #848484; background-color: #bdbcbc; }

  body.menu { background-color: #f2f2f2; }
  body.menu a { color: #000; text-decoration: none; }
  body.menu a:hover { color: #7692E8; text-decoration: underline; }

  body.welcome { margin-right: 100px; }

  body.footer { background-color: #f2f2f2; font: 70% Roboto, Arial, sans-serif; color: #6b6b6b; margin: 0; padding: 8px 0 0 10px; border-top: 1px solid #c2c2c2; }
  body.footer a { color: #6b6b6b; }

  table.container { border-collapse: collapse; }
  td.mainbody { padding: 3.2rem; }
  td.mainbody h3.left { float: left; }

  table.main { border: 2px solid #ccc; background-color: #eee; }
  table.main th { background-color: #eee; }
  table.notice { background-color: #ccc; }
  table.notice th { background-color: #ccc; }
  table.main li { float: left; width: 200px; }
  table.main td.remarks { max-width: 300px; }
  table.main tr.colored_row { background-color: inherit; }
  table.main tr.colored_row2 { background-color: #d2d2d2; }
  table.main tr.colored_row3 td { background-color:#FFFFFF; border-bottom:1px dashed; border-top:1px dashed; }
  table.main tr.cancelled td { color: #aaa; text-decoration: line-through; }
  table.main input.colorswatch { height: 22px; width: 22px; border: 0; background: transparent; margin-right: 5px }
  table.main td.value input,
  table.main input.textfield { padding: 2px; }
  table.main input.yearfield { width: 35px }
  table.main input.monthfield { width: 20px }
  table.main input.dayfield { width: 20px }
  table.main input.timefield { width: 20px }
  table.main select.normal { width: 230px; }
  table.main select.big { width: 400px; }
  table.main input.textfield { width: 400px; }
  table.main input.imagefield { width: 318px; }
  table.main input.smallbutton { font-size: 80%; height: auto; }
  table.main textarea.textarea { width: 400px; height: 150px; font-family: Roboto, Arial, Sans-serif; border: 1px solid #ccc; }
  table.main textarea.tinyMCEarea { width: 398px; height: 150px; font-family: Roboto, Arial, Sans-serif; border: 1px solid #ccc; }
  table.cross tr.vertical td { width: 15px; }
  table.rightpadding th,
  table.rightpadding td { padding-right: 10px; }
  table.main td.name { width: 200px; }
  table.main small { font-weight: normal; white-space: normal; }
  table.main span.alert,
  table.main a.alert { font-weight: bold; color: #ff0000; }
  th a.normal, h6 a.normal { font-weight: normal; text-decoration: underline; }
  th a:hover { color: #7692E8; text-decoration: underline; }
  form.insertnew { float: right; }
  tr.costform select { width: 150px; }

  img.loading { display: none; margin: 0 0 -4px 0; }
  ul.normal li { list-style-type: none; background: none; padding-left: 0; border-bottom: 1px solid #eee;}
  h2.left { float: left; }

  div.view { width: 46%; padding: 0 2px; float: left; margin-right: 15px; }
  div.view label { float: left; width: 200px; }
  div.view div.value { margin-left: 220px; }
  div.view div { padding-bottom: 3px; }
  div.view div.divider { height: 1em; }

  table.infotable th.sorter a,
  table.infotable th.reset a { text-decoration: underline; }
  table.infotable th.sorter img { padding-right: 5px; }

  div.roundedbox { padding: 8px; -moz-border-radius: 5px; border-radius: 5px; background-color: #eee; }

  div.pagination span { margin-right: 2px; font-weight: bold; }
  div.pagination a { margin-right: 2px; }
  div.paging { clear: both; padding: 0 0 10px 0; }

  div.tooltip { z-index: 100; visibility: hidden; position: absolute; left: 0; max-width: 300px; padding: 3px 5px 4px; border: 1px solid #aaa; background-color: #fefefe; text-align: left; -moz-box-shadow: 1px 5px 5px rgba(0,0,0,.2); -webkit-box-shadow: 1px 5px 5px rgba(0,0,0,.2); box-shadow: 1px 5px 5px rgba(0,0,0,.2); }
  div.tooltip table { margin: 5px; }
  div.tooltip table td { padding-right: 5px; }
  div.tooltip td.divider { height: 5px; }
  div.tooltip td.headcell { padding-right: 10px; font-weight: bold; text-align: right; }
  td.infocell { width: 20px; padding: 0; }
  a.toolinfo { margin: 0; padding: 0; text-decoration: none; background: #eee;}

  div.empty { color: #bbb; }
  .disabled { background-color: #DDD; border: 0; margin-right:50px; }
  div.nopad { padding: 0; }
  div.infomessage { margin-left: -2px; padding: 4px 5px 5px; color:#009900; background-color:#e6fedc; border: 1px solid #009900; }
  span.fielderror { font-weight: bold; color: #ff0000; }
  div.notice { margin-bottom:10px; padding: 4px; background-color: #fef5e4; border: 1px solid #fcba5c; color: #f28500; }
  span.hint { padding-left: 10px; color: #777; }
  button.link { padding: 0 2px; border: 0; background: transparent; color: #000; text-decoration: underline; font-family: Roboto; cursor: pointer; }
  a.force_edit { vertical-align:top; }

  div.mail { width: 400px; border: dashed #c2c2c2; background-color:#f2f2f2; padding:10px; margin-top: 15px; }

  td.group_lt,td.group_t,td.group_rt,td.group_l,td.group_r,td.group_lb,td.group_b,td.group_rb { border-style:dashed; border-color: #ccc; }
  td.group_lt,td.group_t,td.group_rt,td.group_l,td.group_r { border-bottom-style: none; }
  td.group_lt,td.group_l,td.group_lb { border-right-style: none; }
  td.group_lb,td.group_b,td.group_rb,td.group_l,td.group_r { border-top-style: none; }
  td.group_rt,td.group_r,td.group_rb { border-left-style: none; }

  button.ajaxbutton,
  a.ajaxbutton,
  input.ajaxbutton {
    font-family: Roboto, sans-serif;
    border: 0;
    text-decoration: none;
    background-color: #eee;
    height: 14px;
    padding: 0;
    margin: -2px 0 2px;
    &:hover {
      cursor: pointer;
    }
  }

  input.time { width: 22px; }
  input.percentage { width: 42px; }
  input.small { font-size: 11px; }

  tr.searchlabel td { padding: 0px 10px 0 10px;}
  td.searchcontainer { width: 100%; padding: 5px 0 0 0; }
  td.searchcontent { width: 66%; padding-bottom: 0; }
  table.searchdetail { width: 100%; border-collapse: collapse; table-layout: fixed;}
  table.searchdetail td.searchcell { width: 100%; text-align: left; }
  table.searchdetail td.searchcell input.search_text { width: 300px; }
  table.searchdetail td.zerospace { padding: 5px 10px 0 10px;}
  table.searchresult { width: 100%; padding-bottom: 10px; border-collapse: collapse; table-layout: fixed;}
  table.searchresult td { padding: 0px 10px;}
  table.searchresult td.editcell { text-align: right; padding-bottom: 0; }
  table.searchresult td.editcell a { margin-left: 5px;}
  table.searchresult td span { padding: 3px 3px 3px 0; width: 180px; float: left; margin-right: 20px; text-align: left; }
  table.searchresult td span a { color:#000000;}
  table.searchresult td span.short { width: 52px; }
  table.searchresult td span.nopad { margin-right: 5px; width: 80px; }

  table.search_result { margin: 5px 0; border-collapse:collapse; width: 550px; }
  table.search_result tr {background-color: #f3f3f3; }
  table.search_result td { padding: 2px 3px 3px 0; }
  table.search_result div.timeblock { margin: 4px 1px 0 0; }
  table.search_result div.available { background-color: #666666; }
  table.dash_result { width:1000px; }
  table.dash_result span.active { font-weight:900; }
  table.thinrows td { padding: 0 3px 0 0; }
  table.location_search { width: 700px;}
  div#location_new_fields table { margin: 5px 0; border-collapse:collapse; }

  span.sprite { display: inline-block; background: url("images/sprite.png") no-repeat; }
  span.sprite.document { width: 14px; height: 17px; background-position: -30px 0; }
  span.sprite.document-jpeg { width: 14px; height: 17px; background-position: -30px -30px; }
  span.sprite.document-pdf { width: 14px; height: 17px; background-position: 0 -30px; }
  span.sprite.document-word { width: 14px; height: 17px; background-position: -60px 0; }

  div.counter { height: 1px; width: 578px; position: absolute; }
  div.counter div.count { float: right; color: #bbb; margin-top: 5px; }
  /* IE8 */ div.counter div.count { margin-top: 4px; }
  /* Webkit */ @media screen and (-webkit-min-device-pixel-ratio:0) { div.counter div.count { margin-top: -2px; } }

  tr.inline td.editable input { border: 1px solid transparent; background-color: transparent; font-size: 100%; outline: none; margin: 0px; font-family: Roboto, Arial, sans-serif; cursor: pointer; }
  tr.inline td.editable input.timefield { width: 15px; }
  tr.inline td.editable input.hour { text-align: right; padding-right: 0px; }
  tr.inline td.editable input.minute { text-align: left; padding-left: 0px; }
  tr.inline td.editable input.editing { background-color: #fff; }
  tr.hover td.editable input { border: 1px solid #ccc; }

  div.searchable, .ajaxbutton { cursor: pointer; }
  td.short { width: 40px; text-align: center; }
  td.short input.button { width: 50px; }

  div.timeblock { display: inline; overflow: hidden; cursor: default; }

  div.calendar_holder { border-radius: 6px; background-color: #e5e5e5; padding: 2px; }
  div.calendar_holder td { font-size:9px; }

  div.cellcolor0 { background: #ddc;}
  div.cellcolor1 { background: #E7E7DB;}

  div.notecontainer { width: 385px; float:right; }

  div.notes { margin-bottom:20px; }
  div.notes ul.tasks a { display: block; margin-bottom: 10px; }
  div.notes div.note { font-style: italic; }

  div.dashboxcontainer div.dashbox { float: left; width: 25%; padding-bottom: 10px; }
  div.dashboxcontainer div.wide { width: 30%; }
  div.dashboxcontainer div.small { width: 20%; }
  div.dashboxcontainer div.dashbox div.inner { min-height: 160px; margin-right: 10px; padding: 5px; border: 2px solid #ccc; background-color: #eee; }
  div.dashboxcontainer div.last div.inner { margin-right: 0; }
  div.dashboxcontainer div.dashbox div.button { float: right; text-align: right; }
  div.dashboxcontainer div.employee div.tools { display: inline-block; }
  div.dashboxcontainer div.contract span.positive { color: #070; font-weight: bold; }
  div.dashboxcontainer div.contract span.negative { color: #700; font-weight: bold; }
  div.dashboxcontainer div.dashbox div.bottom { width: 100%; position: absolute; bottom: 4px; height: 20px; }
  div.dashboxcontainer div.dashbox div.bottom div.button { padding-right: 10px; }
  div.dashboxcontainer div.dashbox.vacation h3:first-of-type { margin-top: 0px; }

  div.dashblock { margin-bottom: 10px; padding: 5px; background-color: #eee; border: 2px solid #ccc; position: relative; }
  div.dashblock div.topright { position: absolute; right: 8px; top: 8px; }
  div.dashblock a { color: #000; }
  div.dashblock table
  div.dashblock div.button { float: right; text-align: right; }
  div.dashblock table.search_result { width: 100%; }
  div.dashblock table.search_result tr { background-color: #eee; }
  div.dashblock table.search_result tr.colored_row { background-color: inherit; }
  div.dashblock table.search_result tr.colored_row2 { background-color: #DFDEDE; }
  div.dashblock td, div.dashblock th { padding-right: 10px; white-space: nowrap; }
  div.dashblock td.short, div.dashblock th.short { width: 86px; text-align: left;}
  div.dashblock.alert{ background-color:#FFE2BB; border-color:#FF9300}

  /* CRM */

  td.em { font-weight: bold; }
  div.formbox { float: left; }
  div.editform textarea { width: 280px; font-family: Roboto, Arial, Sans-serif; margin-right: 5px; }
  div.editform div.dashbox { clear: both; width: inherit; min-width: 340px; }
  div.editform div.submit { clear: both; padding: 0 0 0 120px; }
  div.editform input { width: 280px; margin-right: 5px; }
  div.editform input.short { width: 80px; }
  div.editform input.radiobox { width: inherit; }
  div.editform input.dd  { width: 20px; }
  div.editform input.mm { width: 20px; }
  div.editform input.yyyy { width: 40px; }
  div.editform label,
  div.companyinfo label { display: inline-block; width: 100px; text-align: right; padding-right: 10px; }
  div.editform label.aligntop { vertical-align: top; }
  div.editform label.radiobox { width: 150px; text-align: left; }
  div.editform label.radioboxbig { width: 250px; }
  div.editform p { margin: 0 0 3px; }
  div.editform h3 { clear: both; }
  div.editform div.padleft { padding-left: 40px; }
  table.cccoverview td { padding-right: 15px; }
  table.infotable { width: 100%; }
  table.zebra tr.odd, table.infotable tr.odd { background-color: #ddd; }
  table.infotable th { padding: 1px 3px; background: #ccc; }
  table.infotable td { padding: 1px 3px; vertical-align: middle; }
  div.addclientcontacts { display: none; }
  div.topnotice { background-color: #ffa; color: #333; border-bottom: 0; margin-bottom: 0; }
  div.dashboxcontainer td.wide { width: 820px; }
  div.editinline div.dashbox { border: 0; }
  div.formbox div.hidden { display: none; }
  div.dashblock div.action { margin-top: 6px; padding: 4px 4px; border-top: 1px solid #ccc; }
  div.dashblock table.zebra td, div.dashblock table.zebra th { width: inherit; }
  div.companyinfo label { width: 110px; float: left; font-weight: bold; text-align: left; }
  div.companyinfo label a { font-weight: normal; }
  div.companyinfo div.info { margin-left: 120px; margin-bottom: 1em; }
  div.companyinfo span.colorblock { display: inline-block; width: 20px; height: 15px; border: 1px dotted #555; background: #fff; vertical-align: middle; }

  div.search { float: right; padding: 15px 5px 0; }
  div.search input { border: 1px solid #999; width: 150px; -webkit-rtl-ordering: logical; }
  span.searchwords { display: none; }
  div.searchresults table { width: 100%; }
  div.searchresults table td { width: 25%; }
  div.searchresults table td.last { width: 150px; }

  /* HRM */

  table.fixedexpenses td.amount { text-align: right; width: 80px; }
  form.fixedexpense input.amount { width: 45px; text-align: right; margin-right: 10px; }

  ul.availabilitylegend { padding: 0; margin: 0; list-style: none; }
  ul.availabilitylegend li { float: left; width: 92px; padding: 0; background: none; list-style: none; }
  ul.availabilitylegend div.timeblock { margin: 4px 4px 0 2px; }

  table#date_table { border-collapse:collapse; }
  tr.date_row td { vertical-align: middle; }
  input.date_day { width: 20px; }
  input.date_month { width: 20px; }
  input.date_year { width: 40px; }
  input.date_days,
  input.date_hours { width: 40px; }
  table#date_table td.date_label { width: 84px; padding-left: 0; }
  table#date_table td.day_label { width: 66px; }

  table.contract td.label { width: 150px;}
  table.contract input.datefield { width: 80px; }
  table.contract input.valuefield { width: 40px; }
  table.contract input.decimalfield { width: 20px; }
  table.contract input.disabled { border: none; background-color: #eee; }
  table.contract input.right { text-align: right; }
  table.contract span.proposal { cursor: pointer; }
  table.contract span.proposal em { text-decoration: underline; font-style: normal; }

  div.legend { height: 20px; float: right; font-size: 8pt; font-weight: normal; }
  div.legend div { float: left; padding: 0 10px 0 5px; }
  div.legend div.color { width: 13px; height: 13px; border: 1px solid #ddd;}
  table.contractsoverview td { padding: 3px; border: 0; }
  table.contractsoverview tr.low,
  div.legend div.low { background-color: #fbe693; }
  table.contractsoverview tr.medium,
  div.legend div.medium { background-color: #ffba4d; }
  table.contractsoverview tr.high,
  div.legend div.high { background-color: #ff812f; }
  table.contractsoverview tr.expired,
  div.legend div.expired { background-color: #f33d34; }
  table.contractsoverview tr.notstarted,
  div.legend div.notstarted { background-color: #aaa; }

  div.contract table.dashboard tr td:first-child { width: 100px; }
  table.dashboard { padding: 0; border-spacing: 0; border-collapse: collapse; }
  table.dashboard tr td { padding: 2px 0 0 0; }

  table.timefortime { width: 500px; }
  table.timefortime span.balance { font-weight: bold; }
  table.timefortime span.positive { color: #007f00; }
  table.timefortime span.negative { color: #770000; }
  table.timefortime tr.totals td.balance { border-top: 1px solid black; }

  div.notes table.notes { margin-bottom: 5px; }
  div.notes table.notes tr.notehidden { display: none; }
  div.notes table.notes td { height: 15px; padding-top: 3px; padding-bottom: 3px; }
  div.notes table.notes td { vertical-align: top; }
  div.notes table.notes td.date { width: 50px; }
  div.notes table.notes td.note { width: 800px; white-space: normal }
  div.notes table.notes td.note div.edit { display: none; }
  div.notes table.notes button.ajaxbutton { background-color: transparent; }
  div.notes table.notes input.ajaxbutton { background-color: transparent; }
  div.notes label { float: left; width: 60px; padding: 2px; font-weight: bold; }
  div.notes input.note { width: 800px; padding: 2px 4px; margin: 0 10px 0 0; height: 14px; }
  div.employeepicture { float: right; margin: 3px 2px 4px 0; text-align: center; font-size: 7pt; }
  div.pictureuploadcurrent { display: inline-block; padding-top: 12px; text-align: center; }
  div.pictureuploadcurrent p { margin: 6px 0; }
  form.pictureuploadform div p { margin-top: 0; }

  div.attachments table.attachments { width: auto; margin-bottom: 5px; }
  div.attachments table.attachments th { width: auto; padding-bottom: 3px; }
  div.attachments table.attachments td { width: auto; white-space: normal; }
  div.attachments input.note { width: 800px; padding: 2px 4px; margin: 0 10px 0 0; height: 14px; }
  div.attachments input.note { width: 300px; }
  div.attachments input.smallbutton { font-size: 80%; height: auto; }

  form#jobapplicationsearch span.uncheckable label { color: #999; }

  table#job_applications tr th.status, table#job_applications tr td.status { width: 50px; }
  table#job_applications tr th.title, table#job_applications tr td.title { width: 50px; }
  table#job_applications tr th.name, table#job_applications tr td.name { width: 200px; }
  table#job_applications tr th.applied, table#job_applications tr td.applied { width: 80px; }
  table#job_applications tr th.available, table#job_applications tr td.available { width: 80px; }
  table#job_applications tr th.birthdate, table#job_applications tr td.birthdate { width: 80px; }
  table#job_applications tr th.city, table#job_applications tr td.city { width: 130px; }
  table#job_applications tr th.weekdays, table#job_applications tr td.weekdays { width: 50px; }
  table#job_applications td.note p { margin: 0.3rem 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  table#job_applications tr th.cv, table#job_applications tr td.cv { width: 20px; }
  table#job_applications tr th.actions, table#job_applications tr td.actions { width: 190px; }

  div.dashblock a.view_application { margin-right: 0.3rem; }

  div.employee_interview table.details { width: 400px; }
  div.employee_interview table td:first-child { width: 80px; }
  div.employee_interview table td { white-space: normal; }
  div.employee_interview div.shownote { width: 700px; }
  div.employee_interview div.hidden { display: none; }
  div.dashblock table.aligned td { width: 200px; }
  textarea.bignote { padding: 3px; width: 400px; height: 300px; }
  div.dashboxcontainer div.interviewnearby { border-color: #d3e6c2; background: #e4ffcb; }

  ul.employeelist { list-style-type: none;}
  ul.employeelist li { float: left; width: 250px; list-style: none; }

  tr.title { background: transparent !important; }
  tr.title td { font-weight: bold; padding-top: 10px; border-bottom: 2px solid #ccc; }
  div.exportbutton { float: right; }

  div.dashboard_alert p { display: inline-block; vertical-align: top; margin: 0; line-height: 1.5em; }
  div.dashboard_alert ul { display: inline-block; }
  div.dashboard_alert ul li { background: none; padding: 1px 0; }

  /* OPERATIONS */

  span.tag { text-transform: uppercase; color: #fff; padding: 0 1px; font-size: 70%; font-weight: bold; border-style: solid; border-width: 1px; -moz-border-radius: 4px; border-radius: 4px; }
  span.redtag { border-color: #ff7070 #d40000 #d40000 #ff7070; background-color: #f00; }

  table.timeline tr.toprow td { text-align: justify; white-space: nowrap; background-color: #ffc;}
  table.timeline td { width: 35px; }
  table.timeline td.info,table.timeline tr.toprow td.info { width: auto; white-space: nowrap; background-color:#FFFFFF; }

  table.editassignment td.left { padding-right: 20px; }
  table.editassignment div.indented { width: 150px; padding-left: 8px; padding-top: 12px; }
  table.editassignment div.details table.checkboxes { width: 100%; }
  table.editassignment div.details tr.datetime td.today { width: 180px; }
  table.editassignment div.details tr.datetime td.checkbox { text-align: right; padding: 2px 10px 0 20px; }
  table.editassignment div.details td.pause { padding-left: 20px; }
  table.editassignment div.details td.timelabel { vertical-align: middle; }
  table.editassignment div.details span.autoscheduletimes { display: none; }
  table.editassignment div.details textarea { width: 553px; padding: 2px 25px 2px 4px; margin-bottom: 4px; height: 16px; border: 1px solid #999; resize: none; overflow: hidden; line-height: 1.4em; }
  table.editassignment div.details textarea.disabled { margin: 0px; }
  table.editassignment div.schedule { background-color: #e2e2e2; }
  table.editassignment div.evaluation { background-color: #e2e2e2; }
  table.editassignment div.schedule table.schedule { width: 100%; }
  table.editassignment div.schedule table.schedule td { height: 18px; }
  table.editassignment div.schedule table.schedule td.fullname { width: 147px;}
  table.editassignment div.schedule table.schedule td.time { width: 45px; text-align: center; }
  table.editassignment div.schedule table.schedule td.notes { width: 100px; }
  table.editassignment div.schedule table.schedule td.notes input { width: 96px; }
  table.editassignment div.schedule table.schedule td.updown { width: 20px; }
  table.editassignment div.schedule table.schedule td.info { width: 11px; vertical-align: baseline; }
  table.editassignment div.schedule table.schedule td.updown button { height: 15px; width: 24px; }
  table.editassignment div.evaluation input.days { width: 20px; text-align: center; }
  table.editassignment div.evaluation span.email { padding-left: 30px; color: #999; }
  table.editassignment div.evaluation a.sendNow { float: right; margin-top: 4px; }
  table.editassignment div.evaluation span.sendNow { float: right; margin-top: 4px; }
  table.editassignment div.evaluation div.results { float: right; padding-top: 4px; }
  table.editassignment td.employeesearch div.searchable { float: left; width: 288px; }
  table.editassignment td.employeesearch div.selectable { float: left; display: none; }
  table.editassignment td.employeesearch div.searchable div.searchtoggle { float: left; padding-top: 2px; padding-right: 2em; }
  table.editassignment div.employeesearch div.center { width: 570px; margin: 0 auto; }
  table.editassignment div.details input.minute,
  table.editassignment div.schedule input.minute { border-left: 0px; }
  table.editassignment div.details tr.datetime input.minute { border-left: 1px solid #999; }
  table.editassignment div.details tr.datetime input.minute,
  table.editassignment div.details tr.datetime input.hour { padding: 1px 0px; }
  table.editassignment div.details tr.datetime td.fixedcosts { padding-right: 10px; text-align: right; }
  table.editassignment div.details tr.datetime td.fixedcosts label { padding-top: 2px; }
  table.editassignment div.details tr.datetime td.fixedcosts input { width: 50px; border: 1px solid #999; }
  table.editassignment div.schedulesection button,
  table.editassignment div.schedulesection input.button { font-size: 80%; height: auto; }
  table.editassignment div.schedulesection button { width: 20px; }
  table.editassignment div.schedulesection button img { vertical-align: 0%; }
  table.editassignment div.scheduleselection { margin-top: 3px; }
  table.editassignment div.scheduleselection div.searchbox { width: 734px; -moz-border-radius: 5px; border-radius: 5px; padding: 8px; }
  table.editassignment div.newlocation { background-color: #f3f3f3; }
  table.editassignment label.disabled { color: #777; background: transparent; }

  div#employee_search_box { background-color: #fbfbfb; padding: 8px; margin-bottom: 10px; -moz-border-radius: 5px; border-radius: 5px; }
  div#employee_search_box input.search_text { width: 134px; }
  div#employee_search_box table.search_result { width: 100%; }
  div#employee_search_box table.search_result tr { background-color: transparent; }
  div#employee_search_box table.search_result td { padding: 0; height: 22px; }
  div#employee_search_box table.search_result td.timeline { white-space: nowrap; }
  div#employee_search_box table.search_result td.name { width: 143px; }
  div#employee_search_box table.search_result td.select { width: 50px; padding-left: 12px; }

  #copy-email-signature {
    margin-bottom: 10px;
  }

  #email-signature {
    display: none;

    img {
      border-radius: 50%;
    }
  }

  div#contact_search_box { background-color: #fbfbfb; padding: 8px; margin: 5px 0px 10px; -moz-border-radius: 5px; border-radius: 5px; }
  div#contact_search_box table.search_result tr { background-color: transparent; }

  div#client_search_box { background-color: #fbfbfb; padding: 8px 8px 0px; margin: 5px 0px 10px; -moz-border-radius: 5px; border-radius: 5px; }
  div#client_search_box table.search_result tr { background-color: transparent; }

  div#location_search_box { background-color: #eee; }
  div#location_search_box table.search_result tr { background-color: transparent; }
  div#location_search_results table.location_search { width: 100%; }
  div#location_search_results table.location_search td { height: 18px; }

  div#contact_search_results table.search_result { margin: 5px 0 0; }

  table.confirmations { border-spacing: 0; width: 388px; margin-left: -2px; }
  table.confirmations td.confirmfield input { border: 1px solid #aaa; padding: 2px; }
  table.confirmations table { border-spacing: 0; width: 100%; }
  table.confirmations table td { vertical-align: middle; padding: 3px; }
  table.confirmations table td table tr td { text-align: left; }
  table.confirmations table td.label { width: 60px; }
  table.confirmations table td input[type=text] { width: 230px; }
  table.confirmations table td input[type=checkbox] { margin: 0; padding: 2px; }
  table.confirmation_history { text-align: left; padding: 0px; margin: 0px; border-spacing: 0px; empty-cells: hide; }
  table.confirmation_history td { font-size: 11px; padding: 1px; margin: 0px; }
  table tr.alertmessage { margin-left: -2px; padding: 4px 5px 5px; color: #ff1c1c; background-color: #fff0f0; border: 1px solid #ff9494; }
  table.alertmessage { border: 1px solid #ffc576; background-color: #ffe2bb; border-radius: 5px; }
  table.alertmessage tr { padding: 4px 5px 5px; vertical-align: bottom; }
  table.alertmessage td.confirmfield input { border-color: #ffc576; }

  a.actionlink { text-decoration: none; }

  table.filtercontact td, table.searchdetail td { padding: 11px; overflow: hidden; }
  table.filtercontact th, table.searchdetail th { text-align: left; padding: 10px; overflow: hidden; font-weight: bold; }
  table.filtercontact { width: 100%; border-collapse: collapse; table-layout: fixed;}

  table.formsection { width: 820px; border-collapse: collapse; }
  table.formsection td { padding-left: 0; border-spacing: 0; height: 22px; }
  table.formsection td.formlabel { width: 150px; vertical-align: top; }
  table.formsection tr.spacer td { height: 16px; }
  table.formsection tr.spacersmall td { height: 8px; }
  table.formsection select { border: 1px solid #aaa; }
  table.formsection select.normal { width: 225px; }
  table.formsection select.big { width: 100%; }
  table.formsection input.iColorPicker { width:50px; }
  table.formsection input.datefield { width:80px; border: 1px solid #999; }
  table.formsection input#contact_search,
  table.formsection input#client_search,
  table.formsection input#employee_search,
  table.formsection input#venue_search,
  table.formsection input#car_add,
  table.formsection input#outsourcing_add,
  table.formsection input.timefield { border: 1px solid #999; }
  table.formsection input.timefield { width: 20px; }
  table.formsection input.hour { text-align: right; }
  table.formsection textarea { width: 550px; height: 49px; border: 1px solid #999; }
  table.formsection td.normalsize input { width: 494px; }
  table.formsection td.normalsize select.normal { width: 494px; }
  table.formsection div.leftbox { width: 292px; float: left; }
  table.formsection div.leftbox select.normal { width: 282px; }
  table.formsection div.rightbox { margin: 0; float: left; }
  table.formsection div.rightbox input { width: 286px; border: 1px solid #aaa; padding-left: 2px; }
  table.formsection div.rightbox div.label { display: inline-block; width: 100px; }
  table.formsection div.rightbox select.half { width: 192px; }
  table.formsection div.length { padding-bottom: 2px; font-size: 90%; color: #666; }

  /* FINANCE */

  iframe.invoicepreview { border: 1px outset #c4c4c4; float: left; margin-right: 15px; }

  div.invoicenavigation { margin-top: 10px; padding-left: 3px; }

  div.selections { display: table; margin: 15px 0 5px; background-color: #e6e6e6; }
  div.selections div.monthselection { display: table-cell; width: 350px; padding: 3px; }
  div.selections div.sbuselection { display: table-cell; padding: 3px; padding-bottom: 12px; }
  div.selections div.sbuselection form { display: flex; flex-wrap: wrap; }
  div.selections div.sbuselection form div.sbu { width: 275px; }

  .toggle-complete-container {
    margin: 0.8rem 0;

    label.completed-message {
      display: none;
      color: green;
    }
  }

  span.client-info {
    display: block;
    margin-bottom: 0.8rem;
  }

  div.completed {
    table {
      display: none;
    }

    span.client-info {
      display: none;
    }

    label.completed-message {
      display: inline-block;
    }

    label.not-completed-message {
      display: none;
    }
  }

  .toggle-complete-container {
    margin: 0.5rem 0;

    label.completed-message {
      display: none;
      color: green;
    }
  }

  span.client-info {
    display: block;
    margin-bottom: 0.5rem;
  }

  div.completed {
    table {
      display: none;
    }

    span.client-info {
      display: none;
    }

    label.completed-message {
      display: inline-block;
    }

    label.not-completed-message {
      display: none;
    }
  }

  table.invoice td.columnone { padding-right: 0; }
  table.invoice td.columntwo { padding-right: 30px; }
  table.invoice td.columnthree { width: 200px; padding-right: 10px; }
  table.invoice td.columnthree input { border: 1px solid #aaa; padding: 2px; }
  table.invoice textarea,
  table.invoice select { border: 1px solid #aaa; }
  table.invoice input.changed { background-color: #fff3bc; }

  table.invoice table.invoicehours { width: 420px; margin-bottom: 40px; }
  table.invoice table.invoicehours td { height: 25px; }
  table.invoice table.invoicehours input { border: 1px solid #aaa; padding: 2px; text-align:right; }
  table.invoice table.invoicehours .firstchild,
  table.invoice table.invoicehours .secondchild { width: 80px; }
  table.invoice table.invoicehours td:first-child,
  table.invoice table.invoicehours td:first-child +td { width: 80px; }
  table.invoice table.invoicehours .thirdchild,
  table.invoice table.invoicehours .forthchild { width: 100px; }
  table.invoice table.invoicehours td:first-child + td + td,
  table.invoice table.invoicehours td:first-child + td + td + td { width: 100px; }
  table.invoice table.invoicehours td:first-child + td,
  table.invoice table.invoicehours td:first-child + td + td,
  table.invoice table.invoicehours td:first-child + td + td + td { text-align:right; }
  table.invoice table.invoicehours .secondchild,
  table.invoice table.invoicehours .thirdchild,
  table.invoice table.invoicehours .forthchild { text-align: right; }
  table.invoice table.invoicehours input.smallinput { width: 40px; }
  table.invoice table.invoicehours td.info_icon { width: 20px; vertical-align:middle; }
  table.invoice table.invoicehours table.surcharge_table td.total { font-weight: 900; }
  table.invoice table.invoicehours table.surcharge_table tr.spacer { height: 1px; }
  table.invoice table.invoicehours table.surcharge_table tr.spacer td { line-height:1px; height:1px; font-size:1px; background-color:black;}

  table.invoice table.invoicetotals { margin-bottom: 20px; }
  table.invoice table.invoicetotals td { height: 20px;  }

  table.invoice table.invoiceexpenses td { height: 25px; }
  table.invoice table.invoiceexpenses input { border: 1px solid #aaa; padding: 2px; text-align:right; }

  table.invoice div.uploadedfiles { padding-left: 4px;  }
  table.invoice div.uploadedfiles div.file { height: 2em; }
  table.invoice div.uploadedfiles div.file a span { float: left; padding-right: 5px; }
  table.invoice div.uploadedfiles div.file div.about { display: inline; }
  table.invoice div.uploadedfiles div.file div.description { float: left; width: 185px; overflow: hidden; white-space: nowrap; }
  table.invoice div.uploadedfiles div.file div.specification { float: left; width: 80px; padding-left: 8px; white-space: nowrap; }
  table.invoice div.uploadedfiles div.file div.date { float: left; }

  table.unpaidtotals { width: 750px; }

  img.info_icon { vertical-align:-30%; }
  a.notetitle { display: block; border-bottom: 1px solid #eee; /*width: 185px;*/ margin-bottom:10px; margin-top:5px; font-weight:900;}

  form#invoice div.explanation { float:right; }

  div.unpaidalert { padding-bottom: 5px; }
  div.invoicealert { margin-bottom: 15px; width: 100%; }

  div.billing_address div.view { width: 35%; }
  div.billing_address div.view:first-child { width: 60% }

  div.billing_address table.relatedaddress td { width: auto; }

  form#billing_address_form td { width: auto; }
  form#billing_address_form textarea { width: 75%; height: 100px; padding: 3px; }

  table.main_invoicelist { width: 100%; margin-bottom: 25px; }

  table.main_invoicelist th.center { text-align: center; }
  table.main_invoicelist th.small { width: 50px; }
  table.main_invoicelist th.medium { width: 80px; }
  table.main_invoicelist th.vat { text-align: right; padding-right: 15px; }

  table.main_invoicelist td { padding-left: 3px; padding-right: 2px;}
  table.main_invoicelist td.center { text-align: center; }
  table.main_invoicelist td.value { text-align: right; padding-right: 15px; }
  table.main_invoicelist td.allok input { font-size: 0.8em; }

  table.main_invoicelist td.allok { text-align: center; }
  table.main_invoicelist td.pdf { text-align: center; }
  table.main_invoicelist td.invoiced { text-align: center; padding: 0; }
  table.main_invoicelist td.invoice { text-align: center; }

  table.main_invoicelist tr.perioddivider td { border-top: 2px solid #ccc; }

  table.main_invoicelist tr.outsidecontract { background-color: #fce1e2; }
  table.main_invoicelist tr.tobecredited { background-color: #fcf1e1; }
  table.main_invoicelist td.outsidecontract { text-align: center; padding-top: 5px; }

  table.main_invoicelist tr.cancelled td { color: #7f7f7f; text-decoration: line-through; }
  table.main_invoicelist tr.cancelled td.status,
  table.main_invoicelist tr.cancelled td.pdf { text-decoration: none; }

  table.main_contractlist { margin-bottom: 12px; }

  table.jobgroup td.surchargelabel { padding-top:7px; }
  table.jobgroup table.surcharges td { vertical-align: middle; }
  table.jobgroup table.surcharges tr.show td.time { padding-left: 10px; }
  table.jobgroup table.surcharges tr.show td.percentage { padding-left: 6px; }
  table.jobgroup table.surcharges tr.edit td.time { text-align: center }
  table.jobgroup table.surcharges td.action input { width: 60px; }
  table.jobgroup table.surcharges td.nosurcharges { text-align:center; font-style:italic; }

  table.financedeclarations td,
  table.financedeclarations th { padding: 3px;}
  table.financedeclarations td.bsn,
  table.financedeclarations th.bsn { display: none; }
  table.financedeclarations td.sectionbreak,
  table.financedeclarations th.sectionbreak { border-right: 2px solid #c6c6c6;}
  table.financedeclarations th.to_be_removed,
  table.financedeclarations td.to_be_removed { opacity: 0.5; }
  table.financedeclarations div.tooltip.overtime { min-width: 20.8rem; }

  table.main.all_invoices td,
  table.main.all_invoices th { padding: 0.6rem 1rem; }

  div.tooltip_surcharges table tr td.percentage { width:40px; }
  div.tooltip_surcharges table tr td.timespan { width:100px; }
  div.tooltip_surcharges table tr td.duration { width:50px; }
  div.tooltip_surcharges table tr td.total { width:50px; }
  div.terms .ajaxbutton { background-color: #fff; }
  div.terms textarea { width: 180px; }
  div.terms div.text { font-style: italic; cursor: text; }
  div.terms div.text:hover { background-color: #eee; }
  div.terms div.billingicon { float: right; }

  td.checkboxcolumn { text-align: center; width: 60px; }

  /* QUESTIONNAIRES */

  div.questionnaire { width: 1000px; }
  div.questionnaire div.questiongroup { background-color: #eeeeee; margin-bottom: 5px; }
  div.questionnaire div.questiongroup table.results { width: 100%; }
  div.questionnaire div.questiongroup th.choice { text-align: center; width: 100px; }
  div.questionnaire div.questiongroup td.count { text-align: center; }
  div.questionnaire div.questiongroup td.count a { text-decoration: none; }
  div.questionnaire table.evaluationdetail tr td { padding-bottom: 5px; }
  div.questionnaire table.evaluationdetail th { padding-right: 20px; }
  div.questionnaire hr { border: none; }

  table.evaluations th.date { width: 10%; }
  table.evaluations th.name { width: 15%; }
  table.evaluations th.company { width: 15%; }

  a.pdf-link img { margin-right: 0.5rem; }

  /* SPINNER */
  .spinner-container {
    display: none;
    position: absolute;
    left: 50%;
    text-align: center;
  }
  .spinner-container .spinner {
    width: 25px;
    height: 20px;
    margin: 21px auto;
    text-align: center;
    font-size: 10px;
  }
  .spinner-container .spinner > div {
    background-color: #333;
    height: 100%;
    width: 3px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  .spinner-container .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .spinner-container .spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .spinner-container .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .spinner-container .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .spinner-container span {
    font-size: 1.2em;
  }
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }
  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }

  td.expiring-soon {
    color: rgb(253, 146, 38);

    img {
      margin-right: 0.5rem;
    }
  }

  /* HACKS */

  /* clearfix */
  .clearfix:before,
  .clearfix:after {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  /* webkit */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
  table.editassignment div.details textarea { line-height: 1.3em; }
  div.counter div.count { padding: 6px 1px 0 0; }
  }

  /* END HACKS */
}
