body.new {
  tr.vertical td {
    line-height: 0;
  }

  table.admin.form {
    td {
      padding: 0 0.5em 0 0;
      line-height: 2.2em;
    }
    span {
      margin: 0 0.5em;
    }
  }

  form {
    display: inline;

    input.wide {
      width: 200px;
    }

    input.megawide {
      width: 400px;
    }

    input.smallbutton,
    button.small {
      font-size: 0.8em;
    }

    input.up {
      width: 18px;
      height: 18px;
      border: none;
      background: url("images/sprite.png") no-repeat -90px -60px;
      cursor: pointer;
      outline: none;
      &:active {
        background-position: -90px -61px;
      }
    }

    input.down {
      width: 18px;
      height: 18px;
      border: none;
      background: url("images/sprite.png") no-repeat -90px -90px;
      cursor: pointer;
      outline: none;
      &:active {
        background-position: -90px -89px;
      }
    }

    input.changed {
      padding: 0.2em;
      border: 1px solid #bbb;
      background-color: #fff3bc;
    }

    textarea {
      border: 1px solid #bbb;
      padding: 0.2em;
      font-family: $base-font-family;
      font-size: $base-font-size;
    }

    .field-group .field {
      input[type=text], textarea {
        display: block;
        &.timepicker, &.timefield {
          width: 6rem;
        }
        &.datepicker {
          width: 10rem;
        }
      }
    }

    .moved-to-afas {
      color: red;
    }
  }
}

figure.color-picker-icon {
  width: 1.6rem;
  height: 1.6rem;
  display: inline-block;
  background-image: url('vendor/colorpicker/color.png');
}
