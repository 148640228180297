body {
  .php-container .sidemenu,
  .rails-sidemenu {
    width: 130px;
    min-width: 130px;
    padding: 3.2rem 0 6.4rem 1.6rem;

    background: linear-gradient(102.19deg, #F2F2F2 0%, #DDDDDD 100%);
    line-height: 1.9rem;
    font-family: $new-font-family;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;

    ul {
      padding: 0.5rem 0 0 0;
      margin: 0;
      text-transform: none;
      font-weight: normal;

      li {
        background: none;
        list-style-type: none;
        list-style: none;
        padding: 0;
        padding-right: 1rem;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 0.5rem 0;
          text-decoration: none;
          color: #888888;
        }

        &:hover,
        &.active {
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);

          a {
            color: black;
          }
        }
      }
    }
  }
}
