.invoice-preview {
  width: 85vw;
  margin: 0 auto;
  background-color: white;
  border-radius: 0.2rem;
  box-shadow: 2px 2px 40px 0 rgba(0, 0, 0, 0.23);

  .invoice-preview-container.loading {
    position: relative;
    pointer-events: none;

    .date-input {
      position: unset;
      &:after {
        display: none;
      }
    }

    .loading-container {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);

      .double-spinner {
        display: block;
        margin: 0 auto;
        &:after {
          width: 10rem;
          height: 10rem;
        }
      }
    }
  }

  span.new-invoice-company-name {
    font-weight: normal;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem;

    h1 {
      margin: 0;
    }

    .email {
      p.main {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.8rem;
      }

      p.cc {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.8rem;
        opacity: 0.5;
      }
    }
  }

  form.new {
    .info {
      padding: 1.5rem 3rem;
      background-color: #f5f5f5;

      .date-input {
        display: inline-block;

        &:after {
          right: 3.5rem;
        }
      }

      label {
        margin-right: 1rem;
        font-weight: 500;
      }

      input[type='text'] {
        margin-right: 2.4rem;
        padding: 0.4rem 0.8rem;
        border: 0.1rem solid #cccccc;
        border-radius: 0.3rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }

      input[type='checkbox'] {
        cursor: auto;
      }

      span {
        display: inline-block;
        white-space: nowrap;

        .selectize-control:not(.read-only-selectize) {
          display: inline-block;
          vertical-align: middle;
          margin-right: 2rem;
          min-width: 25rem;
        }

        select#period_type + .selectize-control {
          min-width: 15rem;
        }
      }
    }

    iframe {
      width: 100%;
      height: 60vh;
      border: 0;
    }

    footer {
      padding: 2rem 1.5rem;
      text-align: right;

      .exception-message {
        display: none;
        font-size: 1.6rem;
        color: red;
      }

      .button {
        margin-left: 1rem;
        padding: 1rem 2.5rem;
        font-size: 1.5rem;
        text-transform: capitalize;
      }
    }
  }

  .custom-invoice-lines {
    display: flex;
    flex-wrap: wrap;
  }

  #invoice_lines {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
  }

  .add-new-invoice-line, #invoice_lines .fields, #invoice_lines .overtime-fields {
    display: flex;
    gap: 0.5rem;
    padding: 0.6rem 1rem;
    height: 4.2rem;
    background-color: $light-blue-background-color;
    border-radius: 0.5rem;
    border: 0.1rem solid $medium-divider-color;

    label {
      min-height: 1.6rem;
      margin: 0 0 0.2rem;
      font-size: 1.1rem;
    }

    .invoice_line_amount {
      width: 5.5rem;
      font-size: 1.1rem;
      padding: 0.3rem 0.6rem;
    }

    .selectize-input {
      padding: 0 0.6rem;
      font-size: 1.1rem;

      .item {
        max-width: 11rem;
        white-space: nowrap;
      }
    }

    .invoice_line_category {
      .selectize-input {
        width: 15rem;
      }
    }

    .invoice_line_vat {
      .selectize-input {
        width: 6rem;
      }
    }

    .field {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }
  }

  #invoice_lines .overtime-fields {
    background-color: $light-orange-background-color;
  }

  .add-new-invoice-line a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 4.2rem;
    gap: 0.5rem;
    text-decoration: none;
  }
}

.new-invoice-error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 1.5rem);
  height: 100%;
}
