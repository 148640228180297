div.assignments_overview {
  form {
    input[type=text], button {
      margin-right: 0.8rem;
    }
  }

  & > table {
    border: 2px solid #CCC;
    background-color: #EEE;
    border-collapse: collapse;

    th, td {
      padding: 0.6rem 0.9rem;
    }

    th a {
      cursor: pointer;

      &:after {
        position: absolute;
      }

      &.descending, &.ascending:hover, &:hover {
        &:after {
          content: "\25bc";
        }
      }

      &.ascending, &.descending:hover {
        &:after {
          content: "\25b2";
        }
      }
    }

    tr.cancelled td {
      color: #aaa;
      text-decoration: line-through;
    }

    tr.unassigned td {
      background-color: #FFF;
      border-bottom: 1px dashed;
      border-top: 1px dashed;
    }
  }
}
