form {
  .main-details {
    padding: 3rem;
    margin-bottom: 3rem;
    border-radius: 0.8rem;
    background-color: #F3F3F3;

    .field-group {
      &.block {
        justify-content: space-between;

        .field {
          width: 16rem;
        }
      }

      .field {
        width: 100%;
        &.without-label {
          margin-top: 3rem;
        }

        input[type=text],
        input[type=file],
        input[type=number] {
          width: calc(100% - 1.2rem);
          border: 0.1rem solid #b8b8b8;
          background: white;
        }

        .selectize-input {
          background: white;
        }

        label.toggle {
          margin-left: -1rem;
        }

        textarea {
          width: calc(100% - 2rem);
          height: 5.5rem;
          resize: vertical;
          border: 0.1rem solid #b8b8b8;
          box-shadow: none;
        }

        .selectize-control {
          min-width: 6.5rem;
        }
      }
    }
    &.two-columns {
      display: inline-flex;
      padding: 0;

      .column {
        padding: 3rem;

        h3 {
          margin-top: 0;
        }

        .date-input {
          width: 11rem;
        }

        .month_select {
          width: 12rem;
        }
      }
    }
    &:not(.two-columns) {
      width: 40rem;

      .field-group {
        .field {
          display: flex;
          align-items: center;

          label {
            margin-right: 1rem;
            &:first-child {
              width: 17rem;
            }
          }

          input[type=text],
          input[type=file],
          select,
          textarea,
          .selectize {
            width: unset;
            flex-grow: 1;
          }

          input[type=number] {
            width: 10rem;
          }
        }
      }
    }
  }
}
