.message {
  font-style: italic;
  color: grey;
  margin-bottom: 10px;
}

.listing {
  h1 {
    font-weight: bold !important;
  }

  .results.users {
    td {
      justify-content: space-between;
      .status {
        height: 2.4rem;
        box-sizing: border-box;
        padding: 0.1rem 1rem;
        border-radius: 0.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1.2rem;
        gap: 0.5rem;
        &.active-status {
          background-color: $approval-background-color;
          color: $approval-font-color;
        }
        &.pending-status {
          background-color: $light-blue-background-color;
          color: $blue-font-color;
        }
        &.legacy-status {
          background-color: $light-background-color;
          color: $body-font-color;
        }
        &.inactive-status {
          background-color: $light-orange-background-color;
          color: $light-orange-font-color;
        }
      }
    }
    .contact-details-container,
    .restrictions-details-container,
    .restrictions-details,
    .restrictions-links {
      padding: 1.6rem;
    }

    .restrictions-links {
      margin-top: 0.8rem;

      a.button {
        padding: 0.3rem 3.2rem;
      }
    }

    .actions {
      text-align: right;

      a {
        display: block;
      }
    }

    .user-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      input[type="submit"] {
        border-radius: 4px;
        text-decoration: none;
        text-align: center;
        background-color: #2D89F5;
        color: white;
        border: 1px solid #2D89F5;
        padding: 0.5rem 1.1rem;
        font-size: 12px;
        font-weight: bolder;
      }

      .contact-details-container,
      .restrictions-details-container,
      .notes-container {
        background: white;
        box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
      }

      .contact-details-container,
      .restrictions-details-container {
        display: flex;
        padding: 0 1.6rem;
        justify-content: space-around;
        margin: 1.6rem;
        flex-grow: 1;
      }

      .contact-details,
      .restrictions-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        padding: 1.6rem;
      }
        .submit-button {
          width: 14rem;
          cursor: pointer;
        }

      .user-actions {
        h2 {
          font-size: 17px;
          font-weight: bold;
        }

        .fieldset {
          margin: 1rem 0;
          white-space: nowrap;

          label:not(.toggle) {
            display: inline-block;
            font-weight: bold;
          }
        }

        td.label {
          padding-right: 3.2rem;
          font-weight: bold;
        }

        td {
          padding: 0.5rem 0;
          overflow-wrap: break-word;

          &.service-type-container {
            display: flex;

            a.edit-service-type {
              margin-left: 1rem;
              text-decoration: underline;
              cursor: pointer;
              color: #2d89f5;
            }
          }
        }
      }

      .contact-details-container {
        .profile-picture {
          padding: 1.6rem;
          min-width: 12rem;
          text-align: center;

          .picture {
            display: inline-block;
            position: relative;
            margin-top: 1.2rem;
            margin-bottom: 1.6rem;
            height: 100px;
            width: 100px;
            text-align: left;

            img {
              object-fit: cover;
              background-color: white;
            }

            &:before {
              content: " ";
              display: inline-block;
              background-size: cover;
              background-image: url("images/profile-picture.png");
            }

            &:before,
            img {
              position: absolute;
              height: 100px;
              width: 100px;
              border-radius: 100%;
              box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
            }
          }
        }

        .contact-details {
          padding: 1.6rem;

          label {
            width: 11.2rem;
          }

          a {
            margin: 0 0 1rem;
          }
          input[type="submit"] {
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            background-color: #2D89F5;
            color: white;
            border: 1px solid #2D89F5;
            padding: 0.5rem 1.1rem;
            font-size: 12px;
            font-weight: bolder;
            width: 13.2rem;
            margin: 0 1rem;

            &:disabled {
              background-color: lighten(#2d89f5, 20);
            }

            &:hover:not(:disabled) {
              cursor: pointer;
              background-color: darken(#2d89f5, 10%);
            }
          }

          .restrictions-details {
            input[type="submit"] {
              border-radius: 4px;
              text-decoration: none;
              text-align: center;
              background-color: #2D89F5;
              color: white;
              border: 1px solid #2D89F5;
              padding: 0.5rem 1.1rem;
              font-size: 12px;
              font-weight: bolder;
              width: 13.2rem;
              margin: 0 1rem;
            }
          }


          html body form {
            .input-with-label {
              label {
                display: block;
                margin-bottom: 1rem;
              }
            }

            .input-group {
              display: flex;

              > * {
                margin-right: 1rem;
              }
            }
          }

          .changelog-picker {
            .datepicker-grid {
              width: 11rem;
            }
          }
        }

        .restrictions-details {
          input[type="submit"] {
            border-radius: 4px;
            text-decoration: none;
            text-align: center;
            background-color: #2D89F5;
            color: white;
            border: 1px solid #2D89F5;
            padding: 0.5rem 1.1rem;
            font-size: 12px;
            font-weight: bolder;
            width: 13.2rem;
            margin: 0 1rem;
          }
        }



        .user-actions {
          padding: 1.6rem;
          margin-top: 2.2rem;
          margin-left: auto;
          white-space: nowrap;

          label:not(.toggle) {
            min-width: 9.6rem;
          }
        }
      }
    }
  }
}
