a.billingicon {
  display: inline-block;
  width: 18px;
  height: 1.3rem;
  border: none;
  background: url("images/sprite.png") no-repeat -145px -30px;

  &.missing {
    background-position: -120px -30px;
  }
}
