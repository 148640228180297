nav {
  /* ie7-8 */ display: block;
  position: fixed;
  * html & {
    position: static;
    float: left;
  }
  *:first-child+html & {
    left: 10px;
  }
  width: calc(130px / $base-font-factor);
  ul.main {
    margin-top: 0;
    /* IE */ margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    font-size: 0.7em;
    ul {
      /* IE */ margin-left: 0;
      padding-left: 0;
      list-style-type: disc;
      li {
        margin-left: 1.8em;
      }
    }
  }

  /* navbar should stay properly on top when pages have horizontal scrolling
     remove this hacky solution when refactoring the navigation */
  background: white;
  z-index: 20;
  height: 100%;
  padding-left: 8px;
  margin-left: -8px;
  padding-top: 1em;
  margin-top: -1em;
}
