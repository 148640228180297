body.new {
  div.company {
    line-height: 1.6rem;
  }
}

.listing {
  .results {
    .details-container {
      display: flex;
      flex-wrap: wrap;

      .company-details-container ,
      .financial-details-container {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin: 1.1rem 1.6rem 3.2rem;
        padding: 1.6rem 3.2rem;
        background: white;
        box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.10);

        table {
          td.label {
            padding-right: 3.2rem;
            font-weight: bold;
          }

          td {
            padding: 0.5rem 0;
            overflow-wrap: break-word;
          }
        }

        .company-links {
          padding-top: 1rem;
        }
      }

      .financial-details-container {
        padding-top: 2rem;
      }
    }
  }
}
